import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: false,
    msg: "",
    btnText: "확인",
    func: () => {}
};
export const confirmSlice = createSlice({
    name: "confirm",
    initialState: initialState,
    reducers: {
        showConfirm: (state, action) => {
            state.value = true;
            state.msg = action.payload.msg;
            if (action.payload.btnText) {
                state.btnText = action.payload.btnText;
            }
            if (action.payload.func) {
                state.func = action.payload.func;
            }
        },
        hideConfirm: state => {
            state.value = false;
            state.onClickBtn = false;
        },
    }
})

export const {showConfirm, hideConfirm} = confirmSlice.actions;
export default confirmSlice.reducer;