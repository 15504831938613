import styled from "styled-components"
import mixins, { media } from "../../styles/mixins"

const MsgWrap = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
        display: block;
		${mixins.fontStyle("24px", "400", "32.69px")};
		color: rgba(0, 0, 0, 0.4);
	}

    ${media.mobile`
        p {
            ${mixins.fontStyle("14px", "400", "19.6px")}
        }
    `}
`
const Error = ({msg, error=true}) => {
    return (
        <MsgWrap>
            
            {
            msg?.split("\n")?.map((el, idx) => {
                return (
                    <p key={`msg_${idx}`}>
                        {el}
                    </p>
                )
            })
            }
            {
            (error && msg) && 
                <p>
                    문의 사항은 담당자에게 연락바랍니다.
                </p>
            }
        </MsgWrap>
    )
}
export default Error