import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Form, { ErrorMsg } from "../atoms/Form";
import mixins from "../../styles/mixins";

export const Wrap = styled.div`
    position: relative;

    input {
        ${mixins.label}
    }
    
    &::after {
        content: "";
        position: absolute;
        right: 15px;
        top: 22px;
        margin-top: -7px;
        display: block;
        width: 14px;
        height: 14px;
        background-image: url("${process.env.PUBLIC_URL}/image/icn_selectbox.svg");
        background-size: 14px 14px;
        background-repeat: no-repeat;
        transform: ${(props) => props.$active == "true" ? "rotate(180deg)" : "none"}
    }
`;

const Options = styled.ul`
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    transform: translateY(52px);
    width: 100%;
    background-color: #fff;
    z-index: 10;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    border-radius: 0.3125rem;
    max-height: 300px;
    overflow: auto;

    li {
        border: none;
        // height: 50px;
        padding: 12px 15px;
        box-sizing: border-box;
        ${mixins.label}

        &:hover {
            border: none;
            background-color: rgba(231, 234, 243, 0.3);
        }
    }
`
const SelectOption = React.forwardRef(({data=[], defaultValue = "", error, ...props}, ref) => {
    const [showOptions, setShowOptions] = useState(false);
    const selectBoxRef = useRef();

    useEffect(() => {
        const handleClickDocument = (e) => {
            e.stopPropagation();
            if (selectBoxRef.current) {
                if (!selectBoxRef.current.contains(e.target)) {
                    setShowOptions(false);
                }
            }
        }

        document.addEventListener("click", handleClickDocument);

        return () => {
            document.removeEventListener("click",handleClickDocument);
        }
    },[]);

    return (
        <Wrap ref={selectBoxRef} style={props.style} 
            $active={showOptions ? "true" : undefined}>
            <div>
                <Form.Control
                    isError={error}
                    disabled={props.disabled}
                    onClick={() => {
                        if (!props.disabled) {
                            setShowOptions(true);
                        }
                    }} 
                    placeholder={props.placeholder}
                    value={props.value}
                    readOnly 
                    ref={ref}
                />
                {
                showOptions &&
                <Options className="custom-scroll">
                    {
                        data.map((el) => {
                            return (
                            <li key={el.idx + "_" + el.value} 
                                className="form-control"
                                onClick={(e) => {
                                    setShowOptions(false);
                                    if (props.onChange) {
                                        props.onChange(el.value);
                                    }
                                }}
                            >{el.label}</li>
                        )
                        })
                    }
                </Options>
                }
            </div>

            {error && <ErrorMsg>{error.message}</ErrorMsg>}
        </Wrap>
    )
})

export default SelectOption