import { useMutation } from "@tanstack/react-query";
import api from "../../utils/api"

const fetchActivity = (payload) => {
    if (!payload.applicationIdx) { // 신청
        return api("/application/activity", {
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: payload.formData
        }).then((data) => data.data);
    } else { // 수정
        return api("/application/" + payload.applicationIdx + "/activity", {
            method: "PUT",
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: payload.formData
        }).then((data) => data.data);
    }
}

export const useActivityMutation = () => {
    return useMutation({
        mutationFn: (payload) => fetchActivity(payload)
    })
};