import { useMutation } from "@tanstack/react-query";
import api from "../../utils/api"

const fetchApplictionInfo = (data) => {
    // applicationIdx 없을 경우 -> 신청
    // applicationIdx 있을 경우 -> 수정
    const applicationIdx = data.applicationIdx;

    if (applicationIdx) {
        return api.put(
            `/application/info/${applicationIdx}`, 
            data.formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((data) => data.data);
    } else {
        return api.post(
            `/application/info`, 
            data.formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((data) => data.data);
    }
}

export const useApplicationInfoMutation = (formData) => {
    return useMutation({
        mutationFn: (formData) => fetchApplictionInfo(formData)
    })
};