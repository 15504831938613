import { useQuery } from "@tanstack/react-query";
import api from "../../utils/api";

const fetchNationality = () => {
    return api("application/nationality").then((data) => data.data);
}

export const useNationalityQuery = () => {
    return useQuery({
        retry: 1,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryKey: ["nationality"],
        queryFn: fetchNationality,
        select: (data) => {
            return data.data;
        },
    });
};