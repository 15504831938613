import styled from "styled-components"
import mixins, { media } from "../../styles/mixins";

const Wrap = styled.div`
    background-color: #000;
    padding: 23px 30px;
    ${mixins.fontStyle("40px", "600", "54.48px")}
    color: #fff;
    text-transform: uppercase;
    ${media.mobile`
        padding: 11px 20px;
        ${mixins.fontStyle("20px", "600", "27.24px")}
    `}
`;
export default function FormTitle({children}) {
    return (
        <Wrap>
            {children}
        </Wrap>
    )
}