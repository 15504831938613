import { css } from 'styled-components';


export const breakpoints = {
    mobile: '@media (max-width: 1200px)',
    desktop: '@media (min-width: 1200px)',
};

export const media = Object.entries(breakpoints).reduce((acc, [key, value]) => {
    acc[key] = (first, ...interpolations) => css`
    ${value} {
        ${css(first, ...interpolations)}
    }
    `;
    return acc;
}, {});

const mixins = {
    clampTextLine: (line=3) => css`
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${line};
        overflow: hidden;
    `,

    fontStyle: (fontSize="16px", fontWeight="400", lineHeight="1.2", letterSpacing="0") => css`
        font-size: ${fontSize};
        font-weight: ${fontWeight};
        line-height: ${lineHeight};
        letter-spacing: ${letterSpacing};
    `,

    pxToVw: (px=1, viewportWidth = 1920) => (px/viewportWidth * 100) + "vw",
    pxToVh: (px=1, viewportHeight = 1080) => (px/viewportHeight * 100) + "vh",


    // Typography
    caption: () => css`
        ${mixins.fontStyle("14px", "400", "19px", "-0.03em")}
        
        ${media.mobile`
            ${mixins.fontStyle("11px", "400", "17.6px", "-0.03em")}
        `}
    `,
    description: () => css`
        ${mixins.fontStyle("16px", "400", "25.6px", "-0.03em")}
        
        ${media.mobile`
            ${mixins.fontStyle("12px", "400", "19.2px", "-0.03em")}
        `}
    `,

    label: () => css`
        ${mixins.fontStyle("18px", "400", "24px")}
        ${media.mobile`
            ${mixins.fontStyle("14px", "400", "19px")}
        `}
    `
}

export default mixins;