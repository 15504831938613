import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: []
};
export const customersSlice = createSlice({
    name: "confirm",
    initialState: initialState,
    reducers: {
        changeCustomers: (state, action) => {
            if (action.payload.value) {
                state.value = action.payload.value;
            }
        }
    }
})

export const {changeCustomers} = customersSlice.actions;
export default customersSlice.reducer;