import { useEffect, useState } from "react";

export default function useDeviceSize() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200 ? true : false);

    const setSize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);

        if (window.innerWidth < 1200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", setSize);

        return () => {
            window.addEventListener("resize", setSize);
        }
    },[]);

    return {width, height, isMobile};
}
