import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: false,
};
export const policySlice = createSlice({
    name: "policy",
    initialState: initialState,
    reducers: {
        showPolicy: (state, action) => {
            state.value = true;
        },
        hidePolicy: state => {
            state.value = false;
        },
    }
})

export const {showPolicy, hidePolicy} = policySlice.actions;
export default policySlice.reducer;