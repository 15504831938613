import api from "./api";


export const getS3Url = async (idx) => {
    return await api({
        url: "/attachment/" + idx
    }).then(data => data.data.data);
}

export function setCookie(name, value, options = {}) {

    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}


export function maskingUserInfo(name, value) {
        if (value) {
            switch (name) {
                // case 'abo_name':
                // case 'first_name': {
                //     const regex = /^[ㄱ-ㅎ가-힣]/; // 한글 정규식
                //     if (regex.test(value)) {
                //         if (value.length > 1) {
                //             return value[0] + "*" + value.slice(2);
                //         }
                //     } else {
                //         if (value.length > 2) {
                //             return value.slice(0, 2) + "**" + value.slice(4);
                //         }
                //     }
                //     break;
                // }
                case 'registration_number':
                    return value.slice(0, -6) + '******';
        
                // case 'abo_number':
                //     if (value && value.length > 4) {
                //         return value.slice(0, value.length - 4) + "****";
                //     }
                //     return value;
        
                // case 'phone_number':
                //     return value.replace(/(\d{3})-(\d{4})-(\d{4})/, "$1-****-$3");
        
                case 'passport_number':
                case 'visa_number':
                    return value.slice(0, -5) + '*****';
        
                default:
                    return value; // name이 해당되지 않을 경우 원본 value 반환
            }
        }
    }