import styled from "styled-components"
import mixins, { media } from "../../styles/mixins";

export const ButtonWrap = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 10px;
    border-radius: 12px;
    height: 80px;
    width: 100%;
    &:disabled {
        opacity: 0.5;
    }

    ${mixins.fontStyle("20px", "600", "28px")}
    ${props => {
        switch (props.$bg) {
            case "dark":
                return `
                color: #fff;
                background-color: #06315B;
                border: 2px solid #06315B;
                `
            case "red":
                return `
                color: #fff;
                background-color: #E51C4C;
                border: 2px solid #E51C4C;
                `
            default:
                return `
                color: #06315B;
                background-color: #fff;
                border: 2px solid #06315B;
                `
        }
    }}

    ${media.mobile`
        width: calc(100% - 20px);
        margin: 0 auto;
        height: 54px;
        ${mixins.fontStyle("14px", "600", "19px")}
        gap: 0px 6px;

        svg {
            width: 17px;
        }
    `}
`;
export default function Button({children, ...props}) {
    return (
        <ButtonWrap {...props}>
            {children}
        </ButtonWrap>
    )
}