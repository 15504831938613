import styled from "styled-components"
import FormTitle from "../../organisms/FormTitle"
import PageTemplate from "../../templates/PageTemplate"
import SelectOption from "../../molecules/SelectOption"
import { Wrap as SelectWrap } from "../../molecules/SelectOption"
import mixins, { media } from "../../../styles/mixins"
import moment from "moment"

const PageHeader = styled.div`
    display: flex;
    flex-direction: column;
    
    ${SelectWrap} {
        width: 410px;
        align-self: flex-end;
        margin-top: 30px;
    }

    ${media.mobile`
        
        ${SelectWrap} {
            width: calc(100% - 20px);
            align-self: center;
        }
    `}
`;

const ListDesc = styled.div`
    ${mixins.fontStyle("18px", "400", "28.8px", "-0.02em")}
    color: #858585;

    ${media.mobile`
        ${mixins.fontStyle("14px", "400", "22.4px")}
        padding-right: 20px;
    `}
`;

const TableWrap = styled.div`
    overflow: auto;
    width: 100%;
`;

const PageBody = styled.div`
    margin-top: 40px;
    
    .bold {
        font-weight: 600;
    }

    a {
        color: #000;
        text-decoration: underline;
    }

    .muted {
        color: #728190;
    }

    table {
        width: 100%;
        border: 1px solid #85858533;
        margin-top: 40px;
        
        & ~ ${ListDesc} {
            margin-top: 40px;
        }

        .notice {
            display: block;
            color: #000;
            font-size: 16px;
            line-height: 25.6px;

            &.black {
                color: inherit;
            }
        }

        th {
            background-color: #EFEFEF80;
            padding: 10px 0;
            text-align: center;
            ${mixins.fontStyle("18px", "600", "28.8px", "-0.02em")}
            color: #06315b;
            vertical-align: middle;
            border-right: 1px solid #85858533;
            border-bottom: 1px solid #85858533;
        }

        tbody {
            td {
                padding: 20px;
                border-top: 1px solid #85858533;
                ${mixins.fontStyle("18px", "400", "28.8px", "-0.02em")}
                color: #000;
                word-break: keep-all;
                border-right: 1px solid #85858533;
                vertical-align: middle;

                ul {
                    li {
                        margin-left: 10px;
                        list-style: disc;

                        & ~ li {
                            margin-top: 10px;
                        }

                        &::marker {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    ${media.mobile`
        table {
            min-width: 760px;

            .notice {
                ${mixins.fontStyle("12px", "400", "19.2px", "-0.02em")}
            }

            th {
                ${media.mobile`
                    ${mixins.fontStyle("12px", "600", "19.2px", "-0.02em")}
                `}
            }
            
            tbody {
                td {
                    ${media.mobile`
                        ${mixins.fontStyle("12px", "400", "19.2px", "-0.02em")}
                    `}

                    ul {
                        li {
                            & ~ li {
                                margin-top: 5px;
                            }
                            &::marker {
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
        }
    `}
`;

const Intro = styled.div`
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 60px;
    h1 {
        ${mixins.fontStyle("24px", "600", "32.69px")}
        color: #000;
    }

    p {
        ${mixins.fontStyle("18px", "400", "28.8px", "-0.02em")}
        color: #000;
        margin-top: 40px;
    }

    ${media.mobile`
        padding: 0 20px 40px;

        h1 {
            ${mixins.fontStyle("16px", "600", "21px")}
        }

        p {
            ${mixins.fontStyle("14px", "400", "22.4px")}
            color: #858585;
            margin-top: 20px;
        }
    `}
`;

const Content = styled.div`
    padding-top: 60px;
    ${(props) => {
        if (props.$border) {
            return `
                padding-bottom: 60px;
                border-bottom: 1px solid #EFEFEF;
            `
        }
    }}

    ${media.mobile`
        padding: 40px 20px;
        padding-right: 0;
    `}
`;

const ListTitle = styled.div`
    ${mixins.fontStyle("22px", "600", "35.2px", "-0.02em")}
    color: #06315b;
    margin-bottom: 20px;

    ${media.mobile`
        ${mixins.fontStyle("16px", "600", "21.79px")}
        padding-right: 20px;
    `}
`;


const Privacy = () => {
    const version = "v.1.0";
    const versionDate = "2024-10-2";
    return (
        <>
            <PageHeader>
                <FormTitle>개인정보 처리방침</FormTitle>

                <SelectOption
                    data={[{
                            label: `${version} (${versionDate})`,
                            value: "v1.0"
                        }]}
                    value={`${version} (${versionDate})`}
                ></SelectOption>
            </PageHeader>
            <PageBody>
                <Intro>
                    <h1>
                        Non Cash Award(“NCA”) 여행을 위한 개인정보처리방침
                    </h1>
                    <p>
                        한국암웨이 주식회사(이하 “한국암웨이”)는 이용자의 개인정보를 소중하게 다루고 있으며, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보보호법」을 준수하기 위하여 노력하고 있습니다. 한국암웨이는 개인정보처리방침을 통하여 이용자로부터 제공받은 개인정보를 어떠한 용도와 방식으로 이용하고 있으며, 개인정보보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다.
                    </p>
                </Intro>

                <Content>
                    <ListTitle>1. 개인정보의 수집 항목 및 이용 목적</ListTitle>
                    <ListDesc>
                        한국암웨이는 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은 개인정보를 수집하고 있습니다. 
                        이용자의 개인정보는 밝힌 목적 이외에는 절대로 사용하지 아니하고 개인정보의 사용목적과 용도가 변경될 경우에 반드시 이용자로 등록된 모든 분에게 동의를 구할 것을 약속합니다. 
                        개인정보의 수집 및 이용에 대한 동의 철회 시, 한국암웨이는 일정기간 보관 후 개인정보를 삭제합니다.
                    </ListDesc>
                    <TableWrap>
                        <table>
                            <colgroup>
                                <col style={{width: "150px"}} />
                                <col style={{width: "450px"}} />
                                <col style={{width: "400px"}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>구분</th>
                                    <th>개인정보항목</th>
                                    <th>이용목적</th>
                                    <th>보유 및 이용 기간</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan={4}>
                                        <span className="bold">
                                            개인정보
                                            <br/>(필수)
                                        </span>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>ABO 번호</li>
                                            <li>공동사업자1((구)주사업자) 국영문 이름, 휴대전화번호, 내·외국인 구분, 국적, 성별</li>
                                            <li>공동사업자2((구)부사업자) 혹은 비사업배우자 국영문 이름, 휴대전화번호, 내·외국인 구분, 국적, 성별</li>
                                            <li>(동반가족참석시) 동반 가족 국영문이름, 휴대전화번호, 내·외국인 구분, 국적, 성별, 생년월일</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                참석 대장 여부 확인
                                            </li>
                                            <li>
                                                SMS 공지 등 행사 운영을 위한 이용
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bold">
                                            <li>
                                                행사 종료 후<br/> 즉시 파기
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        영상정보: 행사 시 촬영되는 사진 및 동영상 일체
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                한국암웨이가 발간하는 인쇄물, 운영하는 온라인(인터넷, 모바일)웹사이트 & 앱에 게재
                                            </li>
                                            <li>
                                                한국암웨이의 사내 웹사이트 및 게시판(오프라인 매장 포함) 게재, 언론사, 홍보기사, 유튜브, 기타 웹진 게재
                                            </li>
                                            <li>
                                                기타 회사에서 참가자에게 배포하는 홍보물 제작(미디어, 인쇄물, 스마트TV 등 매체를 통해 전달되는 컨텐츠 등)
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bold">
                                            <li>
                                                공동사업자1, 2<br/>혹은 단독사업자 탈퇴일까지
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bold">
                                        가족관계증명서 혹은 주민등록등본
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                공동사업자1((구)주사업자)와의 관계 증명을 위한 이용
                                                <span className="notice">* 비사업 배우자 및 동반가족 참가신청 시에만 해당</span>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bold">
                                            <li>행사 종료 후<br/> 즉시 파기</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bold">
                                        여권정보(국적, 여권만료일)
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                항공/호텔 예약
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bold">
                                            <li>행사 종료 후<br/> 즉시 파기</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bold" rowSpan={2}>
                                        고유식별정보 (필수)
                                    </td>
                                    <td className="bold">
                                        주민등록번호 또는 외국인 등록번호
                                    </td>
                                    <td>
                                        <ul>
                                            <li>여행자 보험 가입</li>
                                            <li>항공/호텔 예약</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bold">
                                            <li>
                                                행사 종료 후<br/> 즉시 파기
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableWrap>
                    <ListDesc>
                        * 필수 정보는 ABO 윤리강령 소정의 스폰서십 라인 규정 준수 확인 등의 목적으로 이용될 수 있습니다.
                        <br/>* 위 정보는 가입 당시 정보뿐만 아니라 정보 수정으로 변경된 정보를 포함합니다.
                    </ListDesc>

                </Content>
            
                <Content $border>
                    <ListTitle>
                        한국암웨이는 다음과 같은 방법으로 개인정보를 수집합니다.
                    </ListTitle>
                    <ListDesc>
                        - 회원가입 및 서비스 이용 시 이용자가 개인정보 수집에 동의를 하고 NCA 신청 사이트를 통하여 직접 정보를 입력하는 경우 
                        <br/>- 고객센터를 통한 상담 과정에서 메일, 팩스, 전화 등
                        <br/>- 온·오프라인에서 진행되는 이벤트/행사 등 참여
                        <br/>- 기기정보와 같은 생성정보는 NCA 신청 사이트 이용 과정에서 자동으로 생성되어 수집될 수 있음
                    </ListDesc>
                </Content>

                <Content $border>
                    <ListTitle>2. 개인정보의 처리 및 보유기간</ListTitle>
                    <ListDesc>
                        한국암웨이는 이용자의 개인정보를 개인정보의 수집 및 이용목적이 달성되면 지체없이 파기합니다. 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는 경우, 법령상 보존할 기간 동안 이용자의 개인정보를 이 경우, 한국암웨이는 보관하는 정보를 그 보관의 목적으로만 이용합니다.
                    </ListDesc>
                </Content>

                <Content $border>
                    <ListTitle>3. 개인정보의 제3자 제공에 관한 사항</ListTitle>
                    <ListDesc>
                        회원에게 객실예약 등 서비스를 위해 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                        <br/>한국암웨이는 회원의 개인정보를 고지한 범위 내에서 사용하며, 범위를 초과하여 이용하거나 법령에 규정된 경우를 제외하고는 제3자에게 제공하지 않습니다. 
                        <br/>다만 다음의 경우에는 합당한 절차를 통해 회원의 동의를 얻어 개인정보를 제공 또는 이용할 수 있습니다.
                    </ListDesc>

                    <TableWrap>
                        <table>
                            <colgroup>
                                <col style={{width: "150px"}} />
                                <col style={{width: "650px"}} />
                                <col style={{width: "200px"}} />
                                <col style={{width: "200px"}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>제공받는 자</th>
                                    <th>개인정보 수집 항목</th>
                                    <th>수집 및 이용목적</th>
                                    <th>보유 및 이용 기간</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="bold" rowSpan={2}>NCA 예약 대상 호텔</td>
                                    <td className="bold">
                                        <ul>
                                            <li>ABO 번호, 공동사업자1((구)주사업자) 국영문 이름, 공동사업자1((구)주사업자) 국적, 공동사업자1((구)주사업자) 성별, 공동사업자1((구)주사업자) 생년월일</li>
                                            <li>공동사업자2((구)부사업자) 혹은 비사업배우자 국영문 이름, 공동사업자2((구)부사업자) 혹은 비사업배우자 국적, 공동사업자2((구)부사업자) 혹은 비사업배우자 성별, 공동사업자2((구)부사업자) 혹은 비사업배우자 생년월일</li>
                                            <li>(동반 가족 참석시) 동반 가족 국영문 이름, 동반 가족 국적, 동반 가족 성별, 동반 가족 생년월일</li>
                                        </ul>
                                    </td>
                                    <td className="bold" rowSpan={2}>
                                        객실 예약
                                    </td>
                                    <td className="bold" rowSpan={2}>
                                        이용 목적 달성 후 파기
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bold"> 
                                        (고유식별정보): 여권번호
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableWrap>
                </Content>

                <Content $border>
                    <ListTitle>
                        4. 개인정보처리의 위탁에 관한 사항
                    </ListTitle>
                    <ListDesc>
                        한국암웨이는 약속한 서비스 제공 및 향상을 목적으로 이용자의 개인정보의 수집, 취급, 관리 등을 아래 업체에 위탁하여 처리할 수 있으며 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. NCA 신청 사이트의 개인정보 위탁 처리 업체 및 위탁업무 내용은 다음과 같습니다.
                    </ListDesc>
                    <TableWrap>
                        <table className="text-center">
                            <colgroup>
                                <col style={{width: "50%"}} />
                                <col style={{width: "50%"}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>수탁업체(수탁자)</th>
                                    <th>위탁업무 내용 </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        ㈜에스엠컬쳐앤콘텐츠
                                    </td>
                                    <td>
                                        여행자보험 가입 대행, 항공 예약 및 발권 대행, 투어 프로그램 예약 및 운영
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableWrap>
                </Content>

                <Content $border>
                    <ListTitle>5. 개인정보의 국외 이전</ListTitle>
                    <ListDesc>
                        한국암웨이 이용자 개인정보의 국외 이전에 대한 상세 내용은 아래와 같습니다.
                    </ListDesc>
                    <TableWrap>
                        <table class="text-center">
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <th>이전 받는 자</th>
                                    <td>
                                        Marriott International, Inc. (Sheraton Phu Quoc Long Beach Resort)
                                        <br/>(DPO: Bensum Lo / Bensum.Lo@sheraton.com)
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이전 국가
                                    </th>
                                    <td>
                                        미국, 베트남
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이전 항목
                                    </th>
                                    <td>
                                        영문이름, 휴대전화번호, 생년월일, 이메일 주소, 국적, 여권번호, 여권만료일
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이전 시기
                                    </th>
                                    <td>
                                        서비스 이용시
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이전 방법
                                    </th>
                                    <td>
                                        온라인 전송
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이전 유형
                                    </th>
                                    <td>
                                        제3자 제공
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        이용 목적
                                    </th>
                                    <td>
                                        객실 예약 대행
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        보유 및 이용기간
                                    </th>
                                    <td>
                                        이용목적 달성 후 즉시 파기
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableWrap>
                </Content>

                <Content $border>
                    <ListTitle>
                        6. 만 14세 미만 아동에 대한 정보보호에 관한 사항
                    </ListTitle>
                    <ListDesc>
                        한국암웨이는 만 19세 이상만이 이용자 가입이 가능합니다.
                    </ListDesc>
                </Content>

                <Content $border>
                    <ListTitle>
                        7. 개인정보의 관리(열람, 정정, 삭제 등)와 파기 절차 및 방법
                    </ListTitle>
                    <ListDesc>
                    이용자로부터 수집한 개인정보를 수집 · 이용 목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.
                    <br/>이용자는 언제든지 <span className="bold">NCA 신청 사이트</span>에 등록되어 있는 자신의 개인정보를 열람하거나 정정할 수 있으며 이용자 개인정보 삭제를 요청하실 수 있습니다.
                    <br/>단, <span className="bold">NCA 신청 사이트</span>에 자체적으로 생성된 정보 외 amway.co.kr과 연동되는 정보는 <span className="bold">NCA 신청 사이트</span>가 아닌 amway.co.kr을 통해 수정 및 삭제하실 수 있습니다.
                    <br/>또한, 탈퇴 회원의 동일 아이디 재가입 방지를 위한 최소한의 식별 정보는 탈퇴 후 6개월 간 삭제가 불가할 수 있습니다.
                    <br/>이용자는 개인정보의 수집, 이용에 대한 동의 철회(해지) 및 제3자 제공 동의의 철회를 전화, 팩스, 기타의 방법을 통하여 요청할 수 있으며 이 경우, 이용자 본인임을 확인한 후 처리가 이루어지게 됩니다. 수정된 정보는 처리가 완료된 시점부터 바로 적용이 되며 이용자 가입 해지를 원하실 경우 해지 신청 즉시 이용자와의 연락 후 한국암웨이 정책에 준하여 해지 절차를 밟게 됩니다.
                    <br/>이용자가 개인정보의 오류에 대한 정정을 요청한 경우 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
                    <br/>한국암웨이는 이용자의 요청에 의해 해지 또는 삭제된 개인정보를 "개인정보 처리 및 보유기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 있도록 처리하고 있습니다.
                    <br/>
                    <br/>또한 개인정보의 수집 목적 또는 제공받은 목적이 달성된 때에는 이용자의 개인정보를 지체 없이 파기합니다. 파기 절차와 방법은 다음과 같습니다.
                    <br/>
                    <br/>① <span className="bold">개인정보의 파기 절차</span>
                    <br/>이용자가 가입 등을 위해 입력하신 정보는 개인정보의 수집 및 이용 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보유 사유에 따라(개인정보 처리 및 보유기간 참조) 일정기간 저장된 후 파기됩니다. 이용자의 개인정보는 법률에 의한 경유가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
                    <br/>
                    <br/>② <span className="bold">개인정보의 파기 방법</span>
                    <br/>한국암웨이는 귀중한 이용자의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.
                    <br/>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통한 파기
                    <br/>- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법 사용
                    </ListDesc>
                </Content>

                <Content $border>
                    <ListTitle>
                        8. 개인정보의 기술적/관리적 보호 대책
                    </ListTitle>
                    <ListDesc>
                        한국암웨이 <span className="bold">NCA 신청 사이트</span> 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 위조, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
                        <br/>- 이용자의 개인정보는 비밀번호에 의해 철저히 보호되고 있습니다.   이용자 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.   따라서 이용자 여러분께서는 비밀번호를 누구에게도 알려주시면 안됩니다. 본인의 개인정보는 책임 있게 관리되어야 하며 비밀번호나 개인정보 등의 유출에 대해서   회사는 어떠한 책임도 지지 않습니다.
                        <br/>- 한국암웨이는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
                        <br/>- 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고  있으며, 암호 알고리즘 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
                        <br/>- 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있고, 24시간 전문 보안 업체를 통하여 모니터링 및 응급대처방안을 준비하고 있으며,   기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 보호장치를 갖추려 노력하고 있습니다.
                        <br/>- 한국암웨이의 개인정보관련 처리자는 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며,   담당자에 대한 수시 교육을 통하여 개인정보 보호정책의 준수를 항상 강조하고 있습니다.   그리고 사내 정보보호 전담조직 등을 통하여 개인정보 보호정책의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로잡을 수 있도록   노력하고 있습니다.
                    </ListDesc>
                </Content>

                <Content $border>
                    <ListTitle>
                        9. 개인정보보호책임자와 담당부서
                    </ListTitle>
                    <ListDesc>
                        개인정보보호책임자: 배수정 대표이사
                        <br/>- 소속/직위: CEO / CPO / 대표이사
                        <br/>- E-mail: securityteam@amway.com
                        <br/>- 문의 전화 : 1588-0080
                        <br/>
                        <br/>개인정보보호 담당부서
                        <br/>- E-mail: securityteam@amway.com
                        <br/>- 문의 전화: 1588-0080
                    </ListDesc>
                </Content>
                
                <Content $border>
                    <ListTitle>
                        10. 개인정보관련 의견수렴 및 불만 처리
                    </ListTitle>
                    <ListDesc>
                        한국암웨이는 개인정보보호와 관련하여 이용자 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다. 
                        이용자들은 “한국암웨이 개인정보보호책임자와 담당부서”를 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고, 한국암웨이는 이용자들의 신고사항에 대하여 신속한 답변을 드릴 것입니다. 
                        또한 한국암웨이의 “웹사이트” 이용 중 한국 암웨이의 개인정보보호에 관련된 처리에 대해 만족하지 못하실 경우에는 정부에서 운영중인 아래 기관을 통해 분쟁을 조정할 수 있습니다.
                    </ListDesc>
                    <TableWrap>
                        <table className="text-center">
                            <thead>
                                <tr>
                                    <th>구분</th>
                                    <th>홈페이지</th>
                                    <th>전화</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="bold">
                                        경찰청 사이버안전국
                                    </td>
                                    <td>
                                        <a href="http://cyberbureau.police.go.kr">
                                            http://cyberbureau.police.go.kr
                                        </a>
                                    </td>
                                    <td className="muted">
                                        (국번없이) 182
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bold">
                                        대검찰청 사이버수사과
                                    </td>
                                    <td>
                                        <a href="https://www.spo.go.kr/site/spo/main.do">
                                            https://www.spo.go.kr/site/spo/main.do
                                        </a>
                                    </td>
                                    <td className="muted">
                                        (국번없이) 1301
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bold">
                                        개인정보 침해신고센터
                                        <br/>한국인터넷 진흥원 운영
                                    </td>
                                    <td>
                                        <a href="http://privacy.kisa.or.kr">
                                            http://privacy.kisa.or.kr
                                        </a>
                                    </td>
                                    <td className="muted">
                                        (국번없이) 118
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bold">
                                        개인정보 분쟁조정위원회 
                                        <br/>(개인정보보호위원회 운영)
                                    </td>
                                    <td>
                                        <a href="http://kopico.go.kr">
                                            http://kopico.go.kr
                                        </a>
                                    </td>
                                    <td className="muted">
                                        02-1833-6972
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableWrap>
                </Content>

                <Content >
                    <ListTitle>
                        11. 고지의 의무
                    </ListTitle>
                    <ListDesc>
                        본 개인정보처리방침은 {moment(versionDate).format("YYYY년 MM월 D일")}부터 적용됩니다. 내용의 추가, 삭제 및 수정이 있을 시에는 시행일 이전에 공지사항 등을 통하여 고지할 것입니다. 
                        <br/>또한 개인정보 처리방침에 버전번호 및 공고일자, 시행일자 등을 부여하여 개정여부를 쉽게 알 수 있도록 하고 있습니다.
                        <br/>
                        <br/>- 개인정보 처리방침 버전: {version}
                        <br/>- 공고일자: {moment(versionDate).format("YYYY년 MM월 D일")}
                        <br/>- 시행일자: {moment(versionDate).format("YYYY년 MM월 D일")}
                    </ListDesc>
                </Content>
            </PageBody>
        </>
    )
}
export default Privacy