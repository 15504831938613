import { useEffect, useRef, useState } from "react";
import SelectOption from "./SelectOption";
import Form from "../atoms/Form";
import { useDispatch } from "react-redux";
import { showAlert } from "../../reducer/alertSlice";
import { useNationalityQuery } from "../../hooks/api/useNationality";
import { useRelationshipQuery } from "../../hooks/api/useRelationship";
import { Controller, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import ko from 'date-fns/locale/ko';


export default function GetInput ({type="text", name="", uniqueName="", option="", isRequired=false, ...props}){
	const dispatch = useDispatch();
	const form = useFormContext();
	const inputName = uniqueName + "." + name;
	let error = form.getFieldState(inputName, form.formState).error;

	const phone2Ref = useRef();
	const regNum2Ref = useRef();
	
	
	// 주민등록번호/외국인등록번호
	const [isErrorRegistrationNum1, setIsErrorRegistrationNum1] = useState(false);
	const [isErrorRegistrationNum2, setIsErrorRegistrationNum2] = useState(false);

    const nationality = useNationalityQuery();
    const relationship = useRelationshipQuery();

	/* input 비활성
		- false일 경우 undefined로 설정 
	*/
	const [isDisabled, setIsDisabled] = useState(props.disabled);

	const [optionsArr, setOpionsArr] = useState([]);
	const [selectOptions, setSelectOptions] = useState([]);

	// 비자 발급 여부
	const watchVisa = form.watch(uniqueName + ".visa");
	// 참여 여부
	const watchJoin = form.watch(uniqueName + ".is_join");
	// 주민등록번호
	const watchRegiNum = form.watch(uniqueName + ".registration_number");
	// 내국인 구분
	const watchNationalityType = form.watch(uniqueName + ".nationality_type");

	// validation
	// 필수값 체크
	const requiredValidate = {
		required: (v) => {
			if (!isDisabled && isRequired) {
				if (type == "checkbox" || type == "radio") {
					if (v === undefined || v === null || v?.length === 0 || v === false) {
						return "필수 항목을 확인해주세요"
					}
				} else {
					if (v === "" || v === " " || v === undefined || v?.length === 0 || v === null) {
						return "필수 항목을 확인해주세요"
					}
				}
			}
		}
	}
	// 최소 길이 체크
	const minLengthValidate = (min, msg="") => {
		return {
			checkMinLength: v => {
				if (!isDisabled) {
					if (v.length < min) {
						return msg
					}
				}
			}
		}
	}
	
	// 숫자만
	const inputOnlyNumber = (value, max) => {
		if (max) {
			return value.replace(/[^0-9]/g, "").substring(0, max);
		} else {
			return value.replace(/[^0-9]/g, "");
		}
	}
	// 영문, 한글, 띄어쓰기만
	const inputOnlyText = (value) => {
		return value.replace(/[^a-z|A-Z|ㄱ-ㅎ|가-힣|ㆍ|\ ]/g, "");
	}
	// 영문, 띄어쓰기만
	const inputOnlyTextEn = (value, uppercase=false) => {
		if (uppercase) {
			return value.replace(/[^a-z|A-Z|\ ]/g, "").toUpperCase();
		}
		return value.replace(/[^a-z|A-Z|\ ]/g, "");
	}

	const [registerOptions, setRegisterOptions] = useState({
		...requiredValidate
	});

	let options = [];

	useEffect(() => {
		if (option) {
			setOpionsArr(JSON.parse(option.replace(/'/g, '"')));
		}
		
		let addOptions = {};

		switch (name) {
			// 이름
			case "abo_name":
				addOptions = {
					...minLengthValidate(2, "이름을 정확히 입력해주세요")
				};
				break;
			// 여권 영문 성
			case "last_name":
				addOptions = {
					...minLengthValidate(2, "2자 이상 영문으로 입력해주세요")
				};
				break;
			// 여권 영문 이름
			case "first_name":
				addOptions = {
					...minLengthValidate(2, "2자 이상 영문으로 입력해주세요")
				};
				break;
			// 여권번호
			case "passport_number":
				addOptions = {
					...minLengthValidate(7, "여권번호를 확인해주세요")
				};
				break;
		}
		setRegisterOptions({
			...requiredValidate,
			...addOptions
		});
	},[]);

	useEffect(() => {
		let addOptions = {};

		switch (name) {
			// 이름
			case "abo_name":
				addOptions = {
					...minLengthValidate(2, "이름을 정확히 입력해주세요")
				};
				break;
			// 여권 영문 성
			case "last_name":
				addOptions = {
					...minLengthValidate(2, "2자 이상 영문으로 입력해주세요")
				};
				break;
			// 여권 영문 이름
			case "first_name":
				addOptions = {
					...minLengthValidate(2, "2자 이상 영문으로 입력해주세요")
				};
				break;
			// 여권번호
			case "passport_number":
				addOptions = {
					...minLengthValidate(7, "여권번호를 확인해주세요")
				};
				break;
		}
		setRegisterOptions({
			...requiredValidate,
			...addOptions
		});
	},[isDisabled]);

	// 가족 관계 목록 select option 설정
	useEffect(() => {
		if (relationship.data) {
			if (name === "family_type") {
				setOpionsArr(relationship.data);
			}
		}
	},[relationship])
	
	// 국가 목록 selectOptions 설정
	useEffect(() => {
		if (nationality.data) {
			if (name === "nationality") {
				setOpionsArr(nationality.data);
			}
		}
	},[nationality])

	useEffect(() => {
		if (type === "select") {
			if (optionsArr) {
				const temp = [];
				for (let i = 0; i < optionsArr.length; i++) {
					temp.push({
						label: optionsArr[i],
						value: optionsArr[i]
					})
				}
				setSelectOptions(temp);
			}
		}
	},[optionsArr]);

	// 비자 발급 여부에 따른 인풋 비활성화
	useEffect(() => {
		if (watchVisa === "아니오") {
			if (name == "visa_number" || name == "visa_expiration_date") {
				setIsDisabled(true);
				form.clearErrors(`${uniqueName}.${name}`);
			}
		} else {
			if (watchJoin === "참여") {
				if (name == "visa_number" || name == "visa_expiration_date") {
					setIsDisabled(undefined);
				}
			}
		}
	},[watchVisa]);

	useEffect(() => {
		if (watchJoin === "불참") {
			if (name != "is_join") {
				setIsDisabled(true);
				form.clearErrors(uniqueName);
			}
		} else {
			setIsDisabled(false);
			
			if (watchVisa === "아니오") {
				if (name == "visa_number" || name == "visa_expiration_date") {
					setIsDisabled(true);
					form.clearErrors(`${uniqueName}.${name}`);
				}
			}
		}
	},[watchJoin]);

	useEffect(() => {
		if (name === "registration_number") {
			let regNum2 = watchRegiNum?.split("-")[1];
			// 주민등록번호/외국인등록번호 뒷자리 입력에 따라 성별 자동 체크
			if (regNum2) {
				regNum2 = regNum2[0];
				if (regNum2 == "1" || regNum2 == "3" || regNum2 == "5" || regNum2 == "7") {
					form.setValue(uniqueName+".gender", "남");
					form.clearErrors(uniqueName+".gender");
				} else if (regNum2 == "2" || regNum2 == "4" || regNum2 == "6" || regNum2 == "8") {
					form.setValue(uniqueName+".gender", "여");
					form.clearErrors(uniqueName+".gender");
				}
			}
		}
	},[watchRegiNum]);


	switch (type) {
		case "text":
			// 휴대폰 번호
			if (name.includes("phone")) {
				return (
					<Controller
						control={form.control}
						name={inputName}
						rules={{
							validate: {
								...registerOptions,
								checkPhone: (value) => {
									if (!isDisabled) {
										const phone1 = value?.split("-")[1];
										const phone2 = value?.split("-")[2];
										// 1-1. 앞자리나 뒷자리 둘 중 하나라도 값 입력된 경우
										if (phone1 || phone2) {
											// 2. 총 8자리 미만일 경우
											if ((phone1+phone2).length < 8) {
												// 3. 에러
												return "010 이후의 숫자 8자리를 입력해주세요";
											}
										}
									}
								},
							}
						}}
						render={(({field}) => {
							let phone1 = "";
							let phone2 = "";
							if (field.value) {
								phone1 = field.value?.split("-")[1];
								phone2 = field.value?.split("-")[2];
								if (field.value == "010--") {
									field.onChange("");
								}
							}
							return (
								<Form.Group error={error}>
									010 - 
									<Form.Control 
										disabled={isDisabled}
										isError={error}
										maxLength={4}
										ref={field.ref}
										onChange={(e) => {
											const val = inputOnlyNumber(e.target.value)
											field.onChange("010-" + val + "-" +  phone2);
											phone1 = val;
											if (phone1.length == 4) {
												phone2Ref.current.focus();
											}
										}}
										value={phone1}
										/>
									- 
									<Form.Control 
										disabled={isDisabled}
										isError={error}
										maxLength={4}
										ref={phone2Ref}
										onChange={(e) => {
											const val = inputOnlyNumber(e.target.value);
											field.onChange("010-" + phone1 + "-" +  val);
											phone2 = val;
										}}
										value={phone2}
									/> 
								</Form.Group>
							)
						})}
					/>
					
				)
			} 
			// 주민등록번호 or 외국인등록번호
			else if (name == "registration_number") {
				return (
					<Controller 
						control={form.control}
						name={inputName}
						rules={{
							validate: {
								...registerOptions, 
								checkRegistrationNum: (value) => {
									if (!isDisabled) {
										let errorMsg = "";
										let regNum1 = value?.split("-")[0];
										let regNum2 = value?.split("-")[1];
										let isError1 = false;
										let isError2 = false;

										if (regNum1 || regNum2) {
											// 1. 생년월일 6자리 입력
											if (regNum1.length < 6) {
												errorMsg = "생년월일 6자리를 입력해주세요";
												isError1 = true;
											} else { // 생년월일 입력 이후
												isError1 = false;
												// 2-1. 외국인일 경우
												if (watchNationalityType == "외국인") {
													// 3. 뒤 7자리 입력
													if (regNum2) {
														// 4. 뒤 자리 5, 6, 7, 8로 시작하는 지 확인
														if (!(regNum2[0] == 5 || regNum2[0] == 6 || regNum2[0] == 7 || regNum2[0] == 8)) {
															errorMsg = "외국인등록번호 7번째 자리는 5, 6, 7, 8로 시작합니다";
															isError2 = true;
														} else {
															if (regNum2.length != 7) {
																errorMsg = "외국인등록번호 뒤 7자리를 입력해주세요";
																isError2 = true;
															} else {
																isError2 = false;
															}
														}
													} else {
														errorMsg = "외국인등록번호 뒤 7자리를 입력해주세요";
														isError2 = false;
													}
												} 
												// 2-2. 내국인일 경우
												else {
													if (regNum2) {
														// 3. 뒤 자리 1, 2, 3, 4로 시작하는 지 확인
														if (!(regNum2[0] == 1 || regNum2[0] == 2 || regNum2[0] == 3 || regNum2[0] == 4)) {
															isError2 = true;
															errorMsg = "주민등록번호 7번째 자리는 1, 2, 3, 4로 시작합니다";
														} else {
															if (regNum2.length != 7) {
																errorMsg = "주민등록번호 뒤 7자리를 입력해주세요";
																isError2 = true;
															} else {
																isError2 = false;
															}
														}
													}
													else {
														// 4. 뒤 자리 7자리 지 확인
														errorMsg = "주민등록번호 뒤 7자리를 입력해주세요";
														isError2 = true;
													}
												}
											}
										} else {
											isError1 = true;
											isError2 = true;
											errorMsg = "필수 항목을 확인해주세요";
										}
										
										setIsErrorRegistrationNum1(isError1);
										setIsErrorRegistrationNum2(isError2);
										if(isError1 || isError2) {
											return errorMsg;
										}
									}
								}
							}
						}}
						render={(({field}) => {
							let regNum1 = "";
							let regNum2 = "";
							if (field.value) {
								if (field.value.split("-")[0] != undefined && field.value.split("-")[1] != undefined) {
									regNum1 = field.value.split("-")[0];
									regNum2 = field.value.split("-")[1];
								}
								if (field.value == "-") {
									field.onChange("");
								}
							}
							return (
								<Form.Group error={error} isError={error}>
									<Form.Control 
										ref={field.ref}
										disabled={isDisabled}
										isError={isErrorRegistrationNum1}
										maxLength={6}
										onChange={(e) => {
											const val = inputOnlyNumber(e.target.value, 6);
											field.onChange(val + "-" + regNum2);
										}}
										onInput={(e) => {
											if (e.target.value.length == 6) {
												regNum2Ref.current.focus();
											}
										}}
										value={regNum1}
									/> 
									- 
									<Form.Control 
										disabled={isDisabled}
										isError={isErrorRegistrationNum2}
										maxLength={7}
										ref={regNum2Ref}
										onChange={(e) => {
											const val = inputOnlyNumber(e.target.value, 7)
											field.onChange(regNum1 + "-" + val);
										}}
										value={regNum2}
									/> 
								</Form.Group>
							)
						})}
					/>
				)
			}
			else {
				let max = null;
				if (name == "passport_number") {
					max = 9;
				}
				if (name == "allergy") {
					max = 200;
				}
				return (
					<Controller
						name={inputName}
						rules={{
							validate: registerOptions
						}}
						render={({field}) => {
							return (
								<Form.Group>
									<Form.Control
										{...field} 
										disabled={isDisabled}
										isError={error}
										error={error}
										maxLength={max}
										onInput={(e) => {
											if (name == "abo_name") {
												e.target.value = inputOnlyText(e.target.value);
											}
											if (name == "last_name" || name == "first_name") {
												e.target.value = inputOnlyTextEn(e.target.value, true);
											}
											if (name == "passport_number") {
												e.target.value = e.target.value.replace(/[^0-9|a-z|A-Z]/g, "").toUpperCase().substring(0, max);
											}
											if (name == "visa_number") {
												e.target.value = e.target.value.replace(/[^0-9|a-z|A-Z]/g, "").toUpperCase();
											}
											if (name == "height" || name == "weight") {
												e.target.value = inputOnlyNumber(e.target.value);
											}
										}}
									/>
									{name === "height" && <span className="unit">cm</span>}
									{name === "weight" && <span className="unit">kg</span>}
								</Form.Group>
							)	
						}}
					/>
				)
			}
		case "radio":
			if (optionsArr) {
				for (let i = 0; i < optionsArr.length; i++) {
					const option = optionsArr[i];
					options.push(
						<Form.Radio 
							key={"radio_" + inputName + "_" + option}
							register={form.register(inputName, {
								validate: registerOptions
							})}
							label={option} 
							value={option} 
							disabled={isDisabled}
						/>
					)
				}
			}
			return (<Form.Group error={error} isError={error}>{options}</Form.Group>);
		case "checkbox":
			if (optionsArr) {
				for (let i = 0; i < optionsArr.length; i++) {
					const option = optionsArr[i];
					options.push(
						<Form.Checkbox 
							key={"checkbox_" + inputName + "_" + option}
							register={form.register(inputName, {
								validate: registerOptions
							})}
							label={option} 
							value={option} 
							disabled={isDisabled}
						/>
					)
				}
			}
			return (<Form.Group check={true} error={error} isError={error}>{options}</Form.Group>);
		case "file":
			let isForm = false;
			if (inputName.includes("form")) {
				isForm = true;
				error = form.getFieldState(inputName+".value", form.formState).error;
			}
			return (
				<>
					{
					isForm &&
					<input type="hidden" {...form.register(uniqueName + "." + name + ".type", {
						value: "file"
					})} />
					}
					<Controller
						control={form.control}
						name={isForm ? inputName + ".value" : inputName}
						rules={{
							validate: registerOptions
						}}
						render={({field}) => {
							let defaultV;
							if (field.value) {
								if (typeof field.value === "string") {
									defaultV = field.value;
								}
								if (!field.value) {
									defaultV = "";
								}
							}
							return (
								<Form.Group>
									<Form.File 
										{...field}
										disabled={isDisabled}
										error={!isDisabled ? error : null} 
										isError={!isDisabled ? error : false}
										defaultValue={defaultV}	
										onChange = {(e) => {
											if (e) {
												field.onChange(e.target.files[0]);
												const ext = e.target.value.split('.').pop().toUpperCase();
												const mb = 1024 * 1024; //1mb(메가)
												const limitSize = mb * 10;
												const uploadSize = e.target.files[0]?.size;
												if (ext) {
													if (ext !== "JPG" && ext !== "JPEG" && ext !== "PNG" && ext !== "PDF" && ext !== "HEIC" && ext !== "HEIF") {
														dispatch(showAlert({
															msg: "확장자를 확인해주세요."
														}))
														
														e.target.value = "";
														field.onChange("");
														return false;
													} else {
														if (limitSize < uploadSize) {
															dispatch(showAlert({
																msg: "첨부 파일은 최대 10MB까지 등록하실 수 있습니다."
															}))
															
															e.target.value = "";
															field.onChange("");
															return false;
														}
			
														dispatch(showAlert({
															msg: "파일 업로드가 완료되었습니다."
														}))
													}
												}
											} else {
												field.onChange("");
											}
										}}
										accept=".jpg, .jpeg, .pdf, .heic, .heif, .png"
									/>
								</Form.Group>
							)
						}}
					/>
				</>
			)
		case "select":
			return (
				<Controller control={form.control} 
					rules={{
						validate: registerOptions
					}}
					name={inputName} 
					render={({field}) => {
						return (
							<SelectOption 
								disabled={isDisabled}
								placeholder={name == "nationality" ? "국가 선택" : "선택해주세요"}
								error={error} 
								data={selectOptions}
								value={field.value}
								ref={field.ref}
								onChange={(e) => {
									field.onChange(e);
								}}
							/>
						)
					}} 
			/>
			)
		case "date":
			return (
				<Controller
					control={form.control}
					name={inputName}
					rules={{
						validate: registerOptions
					}}
					render={({field}) => {
						let selectedDate;
						if (field.value) {
							selectedDate = field.value;
						}
						return (
							<DatePicker
								onChange={(date, e) => {
									field.onChange(moment(date).format("YYYY-MM-DD"));
									selectedDate = moment(date).format("YYYY-MM-DD");
								}}
								onInputClick={() => {
									if (name === "passport_expiration_date") {
										alert("여권 만료일은 출국일로부터 6개월 이상이어야합니다.\n잔여 유효기간이 6개월 미만인 경우 여권을 재발급 받은 후\n 담당자에게 연락주시기 바랍니다.")
									}
								}}
								selected={selectedDate}
								placeholderText="YYYY-MM-DD"
								dateFormat="YYYY-MM-dd"
								disabled={isDisabled}
								maxDate={name === "passport_issuance_date" ? moment().toDate() : ""}
								minDate={name === "visa_expiration_date" ? moment(props.startDate).toDate() : ""}
								showYearDropdown
								showMonthDropdown
								dropdownMode="select"
								locale={ko}
								customInput={
									<Form.Control 
										datepicker={true} 
										isError={error}
										error={error}
										inputRef={field.ref}
									/>
								}
							/>
						)
					}}
				/>
			)
	}
}
