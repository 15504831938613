import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from "./store/store";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import "./styles/css/theme.css";
import "./styles/css/customize.css";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </QueryClientProvider>
);
