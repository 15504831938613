import { useQuery } from "@tanstack/react-query";
import api from "../../utils/api";

const fetchApplication = (applicationIdx, scheduleIdx) => {
    return api("/application/" + applicationIdx + "?event_schedule_idx=" + scheduleIdx).then((data) => data.data);
}

export const useApplicationQuery = ({applicationIdx, scheduleIdx}) => {
    return useQuery({
        retry: 1,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryKey: ["application", applicationIdx, scheduleIdx],
        queryFn: () => fetchApplication(applicationIdx, scheduleIdx),
        enabled: false,
        select: (data) => {
            return data.data;
        }
    });
};