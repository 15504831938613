import  { Oval } from "react-loader-spinner";
import styled from "styled-components";
const Wrap = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 99;
    position: ${props => props.$layer ? "fixed" : "relative"};
    ${props => {
        if (props.$layer) {
            return `
                left: 0;
                top: 0;
                z-index: 100;
            `
        }
    }}
`;

const Container = styled.div`
    position: sticky;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    align-items: center;
    justify-content: center;
`;
export default function Loading({layer = false, ...props}) {
    return (
        <Wrap className="Loading" $layer={layer ? layer : undefined}>
            <Container>
                {
                    <Oval height={50} width={50} color="#000" secondaryColor="rgba(0,0,0,0.5)" ariaLabel="oval-loading" />
                }
                {props.children}
            </Container>
        </Wrap>
    );
}
