import { useHybrisUserInfoQuery } from "../hooks/api/useHybrisUserInfo";
import { useCustomersInfoQuery } from "../hooks/api/useCustomersInfo";
import { useEffect, useState } from "react";
import { useOauthToken } from "../hooks/api/useOauthToken";
import { useDispatch } from "react-redux";
import { changeCustomers } from "../reducer/customersSlice";

export default function useCheckLogin() {
    const [customerUid, setCustomerUid] = useState();
    const [accessToken, setAccessToken] = useState();
    const getHybrisUserInfo = useHybrisUserInfoQuery();
    const getCustomersInfo = useCustomersInfoQuery(customerUid, accessToken);
    const getOauthToken = useOauthToken();

    const dispatch = useDispatch();

    useEffect(() => {
        // 1. customerUid 요청 성공
        if (getHybrisUserInfo.data) {
            // 2-1. ssoToken으로 customerUid 받아오기 성공
            if (getHybrisUserInfo.data.header.status === "SUCCESS") {
                setCustomerUid(atob(getHybrisUserInfo.data.customerUid));
            } else { // 2-2 customerUid 받아오기 실패
                // 3. 로그인 페이지로 이동
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        }
    },[getHybrisUserInfo]);

    useEffect(() => {
        if (customerUid) {
            // 3.customerUid으로 access_token 받아오기
            getOauthToken.refetch();
        }
        
        if (customerUid && accessToken) {
            // 5. 사용자 정보 받아오기
            getCustomersInfo.refetch();
        }
    },[customerUid, accessToken]);

    // 4. accessToken 받아오기
    useEffect(() => {
        if (getOauthToken.data) {
            setAccessToken(getOauthToken.data.access_token);
        }
    },[getOauthToken]);

    useEffect(() => {
        if (getCustomersInfo.data) {
            // customers 정보 전역으로 저장
            dispatch(changeCustomers({
                value: getCustomersInfo.data.customers
            }));
        }
    },[getCustomersInfo.data]);

    // console.log(getHybrisUserInfo.isError, getCustomersInfo.isError, getOauthToken.isError)
    // if (getHybrisUserInfo.isError || getCustomersInfo.isError || getOauthToken.isError) {
    //     console.log("error");
    //     return "오류가 발생했습니다."
    // }
    // customers 정보 받아오기 성공하면 화면 노출
    if (getCustomersInfo.data) {
        return true
    } 
    if (getCustomersInfo.isError) {
        return false
    }
}