import styled from "styled-components";
import FormTitle from "../../organisms/FormTitle";
import Caption from "../../atoms/Caption";
import { media } from "../../../styles/mixins";
import Form from "../../atoms/Form";
import Button, { ButtonWrap } from "../../atoms/Button";
import FormTable from "../../organisms/FormTable";
import AgreeTermsList from "../../organisms/AgreeTermsList";
import { Wrap as CaptionStyle } from "../../atoms/Caption";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import useDeviceSize from "../../../hooks/useDeviceSize";
import GetInput from "../../molecules/GetInput";
import { Wrap as FormTableWrap } from "../../organisms/FormTable";
import { getS3Url } from "../../../utils/utils";
import { useFormQuery } from "../../../hooks/api/useScheduleForm";
import { useNavigate } from "react-router-dom";
import { useApplicationInfoMutation } from "../../../hooks/api/useApplicationInfo";
import { useApplicationQuery } from "../../../hooks/api/useApplication";
import Error from "../../organisms/Error";
import useApplicationFormUitils from "../../../hooks/useApplicationFormUitils";
import RenderFormTable from "../../organisms/RenderFormTable";
import { showAlert } from "../../../reducer/alertSlice";
import FixedFormTable from "../../organisms/FixedFormTable";
import Loading from "../../organisms/Loading";


const ImgWrap = styled.div`
	margin-top: 40px;
	
	img {
		display: block;
		width: 100%;
	}

	${FormTableWrap} + & {
		margin-top: 80px;
	}
	
	// mo
	${media.mobile`
		margin-top: 0px;
	`}
	// mo
`

const CaptionWrap = styled.div`
	padding: 30px 22px 0;
	// mo
	${media.mobile`
		padding: 20px;
	`}
	// mo
`

const Wrap = styled.div`
	.btn-submit {
		margin-top: 120px;
	}

	${ButtonWrap} {
		margin-top: 60px;
	}

	// mo
	${media.mobile`
		.btn-submit {
			margin-top: 30px;
		}

		${ButtonWrap} {
			width: calc(100% - 40px);
			margin: 30px auto 0;
		}
	`}
	// mo
`;

const Section = styled.section`
	& ~ & {
		margin-top: 120px;
	}

	${CaptionStyle} {
		&.red {
			color: #ff0000;
		}
	}

	.agree-terms-form {
		margin-top: 40px;
	}

	${media.desktop`
		table {
			tbody {
				tr {
					td {
	
						&:not(:first-of-type:last-of-type) {
							width: 420px;
						}
					}
				}
			}
		}
	`}

	${media.mobile`
		& ~ & {
			margin-top: 60px;
		}
		.agree-terms-form {
			padding: 0 20px;
			margin-top: 30px;

			table {
				th {
					padding-left: 0;
				}
			}
		}
	`}
`;

const FormWrap = styled.div`
	${media.mobile`
		background-color: #E8E8E8;
		${FormTableWrap} {
			padding-top: 0;
		}
		${FormTableWrap} ~ ${FormTableWrap} {
			padding-top: 10px;
		}
	`}
`

export const AccompanyTitleWrap = styled.div`
	display: flex;
	justify-content: space-between;

	.btn-delete-family {
		cursor: pointer;
		width: 24px;
		margin-right: 10px;
		margin-top: 3px;
	}
`

const ApplicationForm = ({target, applicationIdx, scheduleIdx, eventIdx, urlPath}) => {

	/* 
	customersInfo 개수에 따른 조합
		1. 1개이면 단독사업자 + 비사업배우자 조합
		2. 2개이면 공동사업자1 + 공동사업자2 조합
	*/
	const customersInfo = useSelector(state => state.customers).value;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// 고정양식
	const formQuery = useFormQuery(scheduleIdx);
	// 기존 신청 정보
    const applicationQuery = useApplicationQuery({applicationIdx, scheduleIdx});
	// 신청/수정
	const applicationMutation = useApplicationInfoMutation();

	// 화면 크기
	const { isMobile } = useDeviceSize(); 
	// console.log("customer",customersInfo);
	// ------ state
	// 공통 양식
	const [paymentFormCategory, setPaymentFormCategory] = useState([]);
	// items1 + items2 (사업자 양식)
	const [itemsForm, setItemsForm] = useState();
	// 동반가족 양식
	const [items3Form, setItems3Form] = useState();
	//동반가족 폼 테이블 개수 (for PC)
	const [items3TableCnt, setItems3TableCnt] = useState(1);
	//사업자 폼 테이블 개수 (for PC)
	const [itemsTableCnt, setItemsTableCnt] = useState(1);

	const [paymentGuide, setPaymentGuide] = useState();
	const [accompanyGuide, setAccompanyGuide] = useState();

	const [aboNumber, setAboNumber] = useState(customersInfo[0]?.uid);

	const [defaultV, setDefaultV] = useState();

	const [disabledBtn, setDisabledBtn] = useState(false);

	const [termsAttachment, setTermsAttachment] = useState([]);

	const [isLoading, setIsLoading] = useState(false);
	// ------ /state

	// react-hook-form
	const form = useForm({
		mode: "onChange",
		values: defaultV,
		shouldFocusError: true
	});

	// 주사업자, 부사업자 필드 (items1, items2)
	const itemsFieldArray = useFieldArray({
		control: form.control,
		name: "items",
	});

	// 동반가족 필드 (items3)
	const items3FieldArray = useFieldArray({
		control: form.control,
		name: "items3",
	});
	// /react-hook-form

	
	// ------ function
	const { 
		getCategoryDescription,
		mergeObj,
	} = useApplicationFormUitils();
	
	// 신청하기 버튼 클릭 > 유효성 검사 미완료
	const onError = () => {
		dispatch(showAlert({
			msg: "필수 항목을 모두 입력해주세요."
		}))
	}
	// 신청하기 버튼 클릭 > 유효성 검사 완료
	const onSubmit = () => {
		const users = [...form.getValues("items")]; // 참가자 배열
		const newData = [];

		// 동반가족 참가 시 배열에 추가
		if (items3FieldArray.fields.length > 0) {
			users.push(...form.getValues("items3"));
		}

		let isDisagree = false; // 필수항목 동의 체크
		users.forEach((user, userIdx) => {
			let fixedForm; // 참가 인원 고정양식 정보
			switch (user.type) {
				case 1:
					fixedForm = formQuery.data.form.items1;
					break;
				case 2:
					fixedForm = formQuery.data.form.items2;
					break;
				case 3:
					fixedForm = formQuery.data.form.items3;
					break;
			}
			// 1-1. 불참일 경우
			if (user.fixed_form.is_join == "불참") {
				// 2. fixed_form의 모든 값 초기화
				for (const key in user.fixed_form) {
					const fixedFormInfo = fixedForm.filter(v => v.name == key)[0]; // 해당 항목 고정양식 정보
					const element = user.fixed_form[key]; // 선택된 값
					// 페이로드로 보낼 해당 유저 객체 초기화
					if (!newData[userIdx]) { 
						newData[userIdx] = {
							...user,
							fixed_form: {

							}
						};
					}
					// 참여 여부 값과 이름은 초기화 X
					if (key !== "is_join" && key !== "abo_name") {
						if (fixedFormInfo.type == "file") { // 파일은 형식 맞춰서 전달 
							newData[userIdx]['fixed_form'][key] = {
								type: "file",
							}
						} else {
							if (key == "abo_number") { // abo 번호는 로그인 시 받아오는 값으로 전달 (초기화 X)
								newData[userIdx]['fixed_form'][key] = aboNumber;
							} else {
								newData[userIdx]['fixed_form'][key] = "";
							}
						}
					} else { 
						// 동반가족이 불참일 경우에는 이름 초기화
						if (!(user.type == 3 && key == "abo_name")) {
							newData[userIdx]['fixed_form'][key] = element ? element : "";
						}
					}
					
				}
				// 3. 필수 약관 동의 값 초기화
				newData[userIdx]['agreement'] = ""
			} else { // 1-2. 참여일 경우
				for (const key in user.fixed_form) {
					const element = user.fixed_form[key]; // 선택된 값
					// 페이로드로 보낼 해당 유저 객체 초기화
					if (!newData[userIdx]) {
						newData[userIdx] = {
							...user,
							fixed_form: {
	
							}
						};
					}
					// name 입력한 값으로 값 설정
					if (key === "abo_name") {
						newData[userIdx]['name'] = element ? element : "";
					}
					// undefined, null -> 빈 문자열 치환
					newData[userIdx]['fixed_form'][key] = (element !== undefined && element !== null) ? element : "";
				}
				if (user.agreement != "Y") { // 필수항목 동의했는지 체크
					isDisagree = true;
				}
			}
			// 수정일 때 추가한 동반 가족 신청서 idx 빈 값으로 초기화
			if (user.type == 3 && user.is_submit == 0) {
				newData[userIdx]['application_idx'] = "";
			}
		})
			
		const formData = {
			event_schedule_idx: scheduleIdx,
			abo_number: customersInfo[0].uid,
			pin: customersInfo[0].levelName,
			form: newData,
			common: form.getValues("common")
		}
		// 신청 시 event_idx도 같이 보내기
		if (!applicationIdx) {
			formData['event_idx'] = eventIdx;
		}

		// 공통 항목 빈 값 문자열 처리
		for (const key in formData.common) {
			const element = formData.common[key];
			if (!element) {
				formData.common[key] = "";
			}
		}

		console.log("values", form.getValues());
		console.log("isValid", form.formState.isValid);

		
		const reqData = {
			formData: formData, 
			aboNumber: aboNumber,
			applicationIdx: applicationIdx
		};
		console.log("data", reqData);

		if (isDisagree) {
			dispatch(showAlert({
				msg: "필수 항목 동의를 확인해주세요."
			}))
		} else {
			setIsLoading(true);
			// 저장
			applicationMutation.mutate(reqData, {
				onSuccess: (data) => {
					const saveApplicationIdx = data.data;
					if (target === "activity") {
						navigate("/application/"+ urlPath +"/activity") // 액티비티 선택화면으로 이동
					} else {
						navigate("/application/complete/" + saveApplicationIdx + "/" + scheduleIdx); // 신청 상세로 이동
					}
				},
				onError: (error) => {
					setIsLoading(false);
					console.log(error);
					dispatch(showAlert({
						msg: error.response?.data?.msg?.ko ? error.response?.data?.msg?.ko : "오류가 발생했습니다."
					}))
				},
				onSettled: () => {
					setIsLoading(false);
				}
			});
		}
	}
	
	/**
	 * 결제 항목 체크
	 * @param {string} name 항목명
	 * @return {boolean} 
	 */
	const checkPaymentCategory = (name) => {
		if (name.includes("payment")) {
			return true;
		}
		return false;
	}

	/**
	 * 동반가족 추가
	 */
	const addItems3 = () => {
		items3FieldArray.append({
			type: 3,
			pin: "FAM",
			name: "",
			agreement: "",
			abo_number: aboNumber,
			is_submit: 0,
			fixed_form: {
				abo_number: aboNumber,
				is_join: "참여",
			},
		}, {
			shouldFocus: true
		});
	}

	// 참가자 추가
	/**
	 *
	 *
	 * @param {*} type user type 
	 * - 1: 주사업자
	 * - 2: 부사업자
	 * - 3: 참가자 
	 * @param {*} typeText
	 */
	const addItems = (type, typeText) => {
		let name = "";
		let phoneNumber = "";
		// 주사업자 관련 필드 설정
		if (type === 1 && customersInfo[0]) {
			//abo_number 설정
			setAboNumber(customersInfo[0]?.uid);
			name = customersInfo[0].name;
			if (customersInfo[0].mobileNumber) {
				phoneNumber = "010-" + customersInfo[0].mobileNumber?.substr(0, 4) + "-" + customersInfo[0].mobileNumber?.substr(4, 4);
			}
		}
		// 부사업자 관련 필드 설정
		if (type === 2 && customersInfo[1]) {
			name = customersInfo[1]?.name;
			if (customersInfo[1].mobileNumber) {
				phoneNumber = "010-" + customersInfo[1].mobileNumber?.substr(0, 4) + "-" + customersInfo[1].mobileNumber?.substr(4, 4);
			}
		}

		let nationalityDefault = {}
		if (formQuery.data.type !== 3) {
			nationalityDefault = {
				nationality: "대한민국"
			}
		}

		itemsFieldArray.append({
			type: type,
			type_text: typeText,
			pin: customersInfo[0].levelName,
			name: name,
			abo_number: customersInfo[0]?.uid,
			agreement: "",
			is_submit: 0,
			fixed_form: {
				abo_number: customersInfo[0]?.uid,
				abo_name: name,
				is_join: "참여",
				phone_number: phoneNumber,
				...nationalityDefault
			}
		}, {
			shouldFocus: false
		});
	}
	// ------ /function

	// ------ effect
	
	// formQuery.data
	// 고정양식
	useEffect(() => {
		if (formQuery.data) {
			const items3Temp = [];
			const paymentTemp = [];
			// 1. 주사업자/부사업자 폼 항목 합친 후 중복 제거
			let uniqueItems;
			if (formQuery.data.type === 3) {
				uniqueItems = formQuery.data.form['items'];
			} else {
				uniqueItems = mergeObj(formQuery.data.form['items1'], formQuery.data.form['items2']);
			}
			setItemsForm(uniqueItems);
			// 기본 필드 추가
			if (itemsFieldArray.fields.length === 0) {
				// 주사업자/부사업자 명칭 설정
				if (formQuery.data.type == 3) { // 명칭 변경
					addItems(1, formQuery.data.name + "1");
					addItems(2, formQuery.data.name + "2");
				} else {
					if (customersInfo.length == 1) {
						addItems(1, "단독사업자");
						addItems(2, "비사업배우자");
					}
					if (customersInfo.length == 2) {
						addItems(1, "공동사업자1");
						addItems(2, "공동사업자2");
					}
				}
			}

			// 3. 동반 가족 있는 경우 고정 양식 설정
			if (formQuery.data.type === 2) {
				if (formQuery.data.form['items3']) {
					formQuery.data.form['items3'].forEach(async el => {
						if (el.name != "family_guide_attachment" && el.is_show == "true") {
							items3Temp.push(el);
						}
						if (el.name == "family_guide_attachment") {
							if (el.is_show == "true") {
								if (formQuery.data.attachment_list?.family_guide_attachment) {
									setAccompanyGuide(await getS3Url(formQuery.data.attachment_list.family_guide_attachment));
								}

							}
						}
					});
					setItems3Form(items3Temp);
				}
			}

			
			// 공통 양식 설정
			formQuery.data.common_form?.forEach(async el => {
				if (checkPaymentCategory(el.name)) {
					if (el.name === "payment_guide_attachment") {
						if (el.is_show == "true") {
							if (formQuery.data.attachment_list?.payment_guide_attachment) {
								setPaymentGuide(await getS3Url(formQuery.data.attachment_list.payment_guide_attachment));
							}
						}
					} else {
						paymentTemp.push(el);
					}
				}
			});
			setPaymentFormCategory(paymentTemp);

			// 참가 약관 동의 이미지
			const termsArr = [];
			for (const key in formQuery.data) {
				if (key.includes("term")) {
					let title = "";
					const value = formQuery.data[key];
					switch (key) {
						case "term_personal_idx":
							title = "개인정보 수집 이용 동의";
							break;
						case "term_unique_idx":
							title = "고유식별 정보 수집 및 이용에 관한 동의";
							break;
						case "term_sensitive_idx":
							title = "고유식별정보 제3자 제공 동의";
							break;
						case "term_provision_idx":
							title = "개인정보 제3자 제공 동의";
							break;
						case "term_transfer_idx":
							title = "개인정보 국외 이전 동의";
							break;
					
						default:
							break;
					}

					termsArr.push({title, value});
				}
			}
			setTermsAttachment(termsArr);
		}
	}, [formQuery.data]);

	// 동반가족 수 변경 시
	useEffect(() => { 
		// 2명마다 테이블 1개 씩 생성
		setItems3TableCnt(Math.ceil(items3FieldArray.fields.length/2));
	}, [items3FieldArray.fields]);

	// 참가자 수 변경 시
	useEffect(() => { 
		// 2명마다 테이블 1개 씩 생성
		setItemsTableCnt(Math.ceil(itemsFieldArray.fields.length/2));
	}, [itemsFieldArray.fields]);

	// 입력폼 값 변경 시
	useEffect(() => {
		const subscription = form.watch((value, { name, type }) => {
			if (type == "change") {
				if (name) {
					const formName = name.split(".")[0];
					const fieldIdx = name.split(".")[1];
					const fixedFormName = formName + "." + fieldIdx + ".fixed_form";
	
					// 내국인 선택 시 
					if (name.includes("nationality_type")) {
						if (!form.getValues(fixedFormName + ".nationality")) {
							if (form.getValues(name) === "내국인") {
								form.setValue(fixedFormName + ".nationality", "대한민국");
								form.clearErrors(fixedFormName + ".nationality");
							}
						}
						
						if (form.getValues(name) === "외국인") {
							form.setValue(fixedFormName + ".nationality", "");
						}

					}
	
					// 이름 변경
					if (name.includes("abo_name")) {
						// 동반 가족
						if (formName === "items3") {
							// name 필드 추가
							form.setValue(`${formName}.${fieldIdx}.name`, form.getValues(name))
						}
					}
	
					// // 필수 항목 미동의 시 신청/액티비티 버튼 비활성화
					// let isDisagree = false;
					// if (name.includes(".agreement")) {
					// 	form.getValues("items")?.forEach(item => {
					// 		if (item.agreement == "N") {
					// 			isDisagree = true
					// 		}
					// 	}) 
					// 	form.getValues("items3")?.forEach(item3 => {
					// 		if (item3.agreement == "N") {
					// 			isDisagree = true
					// 		}
					// 	}) 
					// 	setDisabledBtn(isDisagree);
					// }
				}
			}
		})
		return () => subscription.unsubscribe()
	}, [form.watch])
	
	// (S) ------- 수정인 경우
	useEffect(() => {
		if (applicationIdx) {
			// 신청 정보 불러오기
			applicationQuery.refetch();
		}
	}, [applicationIdx])

	useEffect(() => {
		if (applicationIdx) {
			if (applicationQuery.data) {
				// 신청 정보 fieldArray에 세팅
				const defaultInfo = applicationQuery.data.application; 
				const attachmentInfo = applicationQuery.data.attachment;
				const paymentInfo = applicationQuery.data.payment;
				const information = applicationQuery.data.information;
				let resetData = {
					items: [
						{
							agreement: "",
							type: 1,
							type_text: customersInfo.length == 1 ? "단독사업자" : "공동사업자1",
							fixed_form: {
								is_join: "불참",
							},
						},
						{
							agreement: "",
							type: 2,
							type_text: customersInfo.length == 1 ? "비사업배우자" : "공동사업자2",
							fixed_form: {
								is_join: "불참",
							},
						}
					]
				};
				let items3Idx = 0;

				defaultInfo.forEach((user, userCnt) => {
					let fieldIdx = 0;
					switch (user.type) {
						case 1:
							fieldIdx = 0;
							break;
						case 2:
							fieldIdx = 1;
							break;
					}

					// 고정 양식 설정
					if (user.type != 3) { // 주사업자/부사업자
						let userName;
						if (user.type == 1) {
							userName = customersInfo[0]?.name;
						} else if (user.type == 2 && user.type_text == "공동사업자2") {
							userName = customersInfo[1]?.name;
						} else {
							userName = user.fixed_form_value.filter(v => v.name === "abo_name")[0]?.value;
						}
						resetData.items[fieldIdx]['application_idx'] = user.idx;
						resetData.items[fieldIdx]['is_submit'] = 1;
						resetData.items[fieldIdx]['type'] = user.type;
						resetData.items[fieldIdx]['type_text'] = user.type_text;
						resetData.items[fieldIdx]['pin'] = information.pin;
						resetData.items[fieldIdx]['abo_number'] = information.abo_number;
						resetData.items[fieldIdx]['name'] = userName;
						
						
						user.fixed_form_value.forEach((fixedForm, fixedFormIdx) => {
							if (fixedForm.name == "is_join") {
								if (fixedForm.value == "참여") { // 참여 인원만 필수 항목 동의 Y 선택되어 있도록
									resetData.items[fieldIdx]['agreement'] = "Y";
								}
							}
							if (fixedForm.name.includes("attachment") || fixedForm.type == "file") {
								if (!resetData['items'][fieldIdx]['fixed_form'][fixedForm.name]) {
									resetData['items'][fieldIdx]['fixed_form'][fixedForm.name] = {
										type: "file",
										value: fixedForm.value
									};
								} 
							} else {
								if (fixedForm.name == "abo_name") {
									resetData['items'][fieldIdx]['fixed_form'][fixedForm.name] = userName;
								} else {
									resetData['items'][fieldIdx]['fixed_form'][fixedForm.name] = fixedForm.value ? fixedForm.value : "";
								}
							}
						});

					} else { // 동반가족
						user.fixed_form_value.forEach((fixedForm, fixedFormIdx) => {
							if (!resetData['items3']) {
								resetData['items3'] = [];
							}
							if (fixedFormIdx === 0) {
								resetData['items3'][items3Idx] = {
									...resetData['items3'][items3Idx],
									agreement: "",
									application_idx: user.idx, 
									is_submit: 1,
									type_text: user.type_text,
									type: 3,
									pin: "FAM",
									name: "",
									abo_number: aboNumber,
									fixed_form: {}
								};
							}
							
							if (fixedForm.type == "file") {
								if (!resetData['items3'][items3Idx]['fixed_form'][fixedForm.name]) {
									resetData['items3'][items3Idx]['fixed_form'][fixedForm.name] = {
										type: "file",
										value: fixedForm.value
									};
								}
							} else {
								resetData['items3'][items3Idx]['fixed_form'][fixedForm.name] = fixedForm.value ? fixedForm.value : "";
								if (fixedForm.name == "is_join") { // 참여 인원만 약관 동의 Y 선택
									if (fixedForm.value == "참여") {
										resetData['items3'][items3Idx]['agreement'] = "Y"
									}
								}
							}
						})
						items3Idx++;
					}
				})

				for (const key in attachmentInfo) {
					if (!resetData['common']) {
						resetData['common'] = {};
					}
	
					resetData['common'][key] = attachmentInfo[key] ? attachmentInfo[key] : "";
				}

				for (const key in paymentInfo) {
					if (!resetData['common']) {
						resetData['common'] = {};
					}
	
					resetData['common'][key] = paymentInfo[key] ? paymentInfo[key] : "";
				}

				console.log("resetData", resetData);
				setDefaultV(resetData);
			}
		}
	},[applicationQuery.data, applicationIdx]);

	useEffect(() => {
		if (applicationIdx) {
			// 기본 값 세팅 완료시 까지 로딩 인디케이터 노출
			if (defaultV) {
				setIsLoading(false);
			} else {
				setIsLoading(true);
			}
		}
	}, [defaultV, applicationIdx]);
	// (E) ------- 수정인 경우

	useEffect(() => {
		const preventEnter = (e) => {
			if (e.keyCode === 13) {
				e.preventDefault();
			};
		}
		document.addEventListener('keydown', (e) => preventEnter(e), true);
		console.log(applicationIdx ? "수정" : "신청");

		return () => {
			document.removeEventListener('keydown', preventEnter);
		}
	},[]);	
	// ------ /effect

	// 신청 수정
	if (applicationIdx) {
		// 신청 수정 에러
		if (applicationQuery.isError) {
			return (
				<Error msg={"신청 정보를 불러올 수 없습니다."}/>
			)
		}
	}

	// 로딩
	if (formQuery.isLoading || applicationQuery.isLoading) {
		return <Loading layer={true} />
	}

	return (
		formQuery.data &&
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit, onError)}>
					<Wrap>
						<Section>
							<FormTitle>참가자 정보</FormTitle>

							<CaptionWrap>
								<Caption>- <span style={{color: "#FF0000"}}>*</span> 표시는 필수 입력사항입니다.</Caption>
								<Caption>- 첨부파일은 JPG, PNG, PDF, HEIC, HEIF 형식으로 최대 10MB까지 등록하실 수 있습니다.</Caption>
							</CaptionWrap>

							{/* 주사업자, 부사업자 */}
							<FormWrap>
								<RenderFormTable 
									fieldArray={itemsFieldArray} 
									fixedForm={itemsForm} 
									tableCnt={itemsTableCnt} 
									formType={formQuery.data.type}
									startDate={formQuery.data.start_date}
								/>
							</FormWrap>

							{
							// 명칭변경 고정양식일 경우 참가자 추가 버튼 노출
							formQuery.data.type === 3 && 
							<Button type="button" onClick={() => { 
								addItems(3, (formQuery.data.name ? formQuery.data.name : "") + (itemsFieldArray.fields.length + 1))
							}}>
								<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="10" y="5" width="1" height="11" fill="#06315B"/>
									<rect x="16" y="10.0001" width="1" height="11" transform="rotate(90 16 10.0001)" fill="#06315B"/>
									<circle cx="10.5" cy="10.5" r="10" stroke="#06315B"/>
								</svg>
								참가자 추가
							</Button>
							}
						</Section>

						{
							formQuery.data.type == "2" &&
							<>
								{/* 동반 가족 */}
								<Section>
									<FormTitle>동반가족 정보</FormTitle>
									
									{
									accompanyGuide &&
									<ImgWrap>
										<img src={accompanyGuide} />
									</ImgWrap>
									}

									<RenderFormTable 
										fieldArray={items3FieldArray} 
										fixedForm={items3Form} 
										tableCnt={items3TableCnt} 
										startDate={formQuery.data.start_date}
									/>


									{/* 동반가족 추가 버튼 */}
									<Button 
									type="button"
									onClick={() => {
										addItems3();
									}}>
										<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
											<rect x="10" y="5" width="1" height="11" fill="#06315B"/>
											<rect x="16" y="10.0001" width="1" height="11" transform="rotate(90 16 10.0001)" fill="#06315B"/>
											<circle cx="10.5" cy="10.5" r="10" stroke="#06315B"/>
										</svg>
				
										동반가족 추가
									</Button>
									
								</Section>
								{/* //동반 가족 정보 */}
							</>
						}
						
						{
						formQuery.data.common_form &&
						<Section>
							<FormTitle>
								공통 제출 서류
							</FormTitle>
							<FixedFormTable>
								<thead></thead>
								<tbody>
									{
										formQuery.data.common_form?.map((commonEl) => {
											const description = getCategoryDescription(commonEl.name);

											if (!checkPaymentCategory(commonEl.name)) {
												if (commonEl.is_show == "true") {
													if (isMobile) {
														return (
															<React.Fragment key={`common_category_${commonEl.name}`}>
																<tr className="border-none">
																	<th className={commonEl.is_required == "true" ? "required" : ""}>
																		<span>
																			{commonEl.title}
																		</span>
																	</th>
																</tr>
																<tr className={commonEl.name == "family_attachment" ? "border-none" : ""}>
																	<td>
																		<GetInput 
																			type={commonEl.type} 
																			name={commonEl.name} 
																			uniqueName="common" 
																			isRequired={commonEl.is_required == "true"} 
																			option={JSON.stringify(commonEl.options)} 
																		/>
																	</td>
																</tr>
																{
																	description &&
																	<tr className="desc">
																		<td>
																			{description}
																		</td>
																	</tr>
																}
															</React.Fragment>
														)
													} else {
														return (
															<tr key={`common_category_${commonEl.name}`}>
																<th className={commonEl.is_required == "true" ? "required" : ""}>
																	<span>
																		{commonEl.title}
																	</span>
																	{
																		description && getCategoryDescription(commonEl.name)
																	}
																</th>
																<td>
																	{
																		commonEl.name !== "payment_guide_attachment" &&
																		<GetInput type={commonEl.type} 
																			name={commonEl.name} 
																			uniqueName="common" 
																			isRequired={commonEl.is_required == "true"} 
																			option={JSON.stringify(commonEl.options)} 
																		/>
																	}
																</td>
															</tr>
														)
													}
												}
											}
										})
									}
								</tbody>
							</FixedFormTable>

							{
								paymentGuide &&
								<ImgWrap>
									<img src={paymentGuide}/>
								</ImgWrap>
							}
							<FixedFormTable>
								<thead></thead>
								<tbody>
									{
										paymentFormCategory.map((paymentEl, idx) => {
											let isShow = paymentEl.is_show == "true";
											const paymentType = form.watch("common.payment_type");
											if ((paymentType === "계좌이체" || !paymentType) && paymentEl.name == "payment_phone") {
												isShow = false;
											}
											if (paymentType === "카드" && paymentEl.name == "payment_name") {
												isShow = false;
											}
											if (isShow) {
												if (paymentEl.name !== "payment_guide_attachment") {
													if (isMobile) {
														return (
															<React.Fragment key={`payment_category_${paymentEl.name}`}>
																<tr className="border-none">
																	<th className={paymentEl.is_required == "true" ? "required" : ""}>
																		<span>
																			{paymentEl.title}
																		</span>
																	</th>
																</tr>
																<tr>
																	<td>
																		<GetInput 
																			type={paymentEl.type} 
																			name={paymentEl.name} 
																			uniqueName="common" 
																			isRequired={paymentEl.is_required == "true"} 
																			option={JSON.stringify(paymentEl.options)} 
																		/>
																	</td>
																</tr>
															</React.Fragment>
														)
													} else {
														return (
															<tr key={`payment_category_${paymentEl.name}`}>
																<th className={paymentEl.is_required == "true" ? "required" : ""}>
																	<span>
																		{paymentEl.title}
																	</span>
																</th>
																<td>
																	<GetInput 
																		type={paymentEl.type} 
																		name={paymentEl.name} 
																		uniqueName="common" 
																		isRequired={paymentEl.is_required == "true"} 
																		option={JSON.stringify(paymentEl.options)} 
																	/>
																</td>
															</tr>
														)
													}
												}
											}
										})
									}
								</tbody>
							</FixedFormTable>
						</Section>
						}

						{/* 참가 약관 동의 */}
						
						<Section>
							<FormTitle>참가 약관 동의</FormTitle>

							<>
								{
									termsAttachment.length > 0 &&
									termsAttachment.map((attachmentInfo, attachmentIdx) => {
										return (
											attachmentInfo.value &&
											<AgreeTermsList key={`att_${attachmentInfo.value}_${attachmentIdx}`} title={`[필수] ${attachmentIdx+1}. ${attachmentInfo.title}`}
												contentImgIdx={attachmentInfo.value} 
											/>
										)
									})
								}
							</>

							<div className="agree-terms-form">
								<Caption color="#ff0000">
									* 각 동의하는 항목에는 참여하는 참가자들이 모두 읽고 동의 여부를 직접 선택 해주시기 바랍니다.
								</Caption>
								<FixedFormTable>
									<thead>
										<tr>
											<th colSpan={2}>
												<span>
													필수 항목 동의
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{
											itemsFieldArray.fields.map((el, idx) => {
												if (form.watch(`items.${idx}.fixed_form.is_join`) == "참여") {
													return (
														<tr key={"agree-terms-accompany-"+el.id}>
															<th>
																<span>
																{el.type_text} (필수)
																</span>
															</th>
															<td>
																<Form.Group>
																	<Form.Radio label="동의" value="Y" register={form.register(`items.${idx}.agreement`)} />
																	<Form.Radio label="동의 안함" value="N" register={form.register(`items.${idx}.agreement`)} />
																</Form.Group>
															</td>
														</tr>
													)
												}
											})
										}
										{
											items3FieldArray.fields.map((el, idx) => {
												if (form.watch(`items3.${idx}.fixed_form.is_join`) == "참여") {
													return (
														<tr key={"agree-terms-accompany-"+el.id}>
															<th>
																<span>
																동반가족{idx+1} (필수)
																</span>
															</th>
															<td>
																<Form.Group>
																	<Form.Radio label="동의" value="Y" register={form.register(`items3.${idx}.agreement`)} />
																	<Form.Radio label="동의 안함" value="N" register={form.register(`items3.${idx}.agreement`)} />
																</Form.Group>
															</td>
														</tr>
													)
												}
												
											})
										}
									</tbody>
								</FixedFormTable>
							</div>
						</Section>
						{/* 참가 약관 동의 */}

						{
						<Button 
							className="btn-submit" 
							type="submit"
							$bg="dark" 
							disabled={disabledBtn}
							onClick={() => {
								console.log(form.getValues(), form.formState);
							}}
						>
							{
								target === "activity"
								?
								"액티비티 선택하기"
								:
								"신청하기"
							}
						</Button>
						}
					</Wrap>
				</form>
			</FormProvider>
			{
				isLoading &&
				<Loading layer={true} />
			}
		</>
	)
}

export default ApplicationForm;
