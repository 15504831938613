import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchOauthToken = (queryData) => {
    return axios("/authorizationserver/oauth/token?grant_type=client_credentials&client_id=EVENT-CLIENT&client_secret=event-0085-415a-b218-a90f014f0ef2", {
        baseURL: process.env.REACT_APP_VITE_HYBRIS_OCC_URL,
        method: "POST"
    }).then((data) => {
        return data
    });
};

export const useOauthToken = () => {
    return useQuery({
        retry: 1,
        queryKey: ["getOauthToken"],
        queryFn: fetchOauthToken,
        enabled: false,
        select: (data) => {
            return data.data;
        },
    });
};