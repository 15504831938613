import { useQuery } from "@tanstack/react-query";
import api from "../../utils/api";

const fetchCustomersInfo = (queryData) => {
    return api("/api/v2/amwaykorea/acontent/customers/Uid/" + queryData.queryKey[1], {
        baseURL: process.env.REACT_APP_VITE_HYBRIS_OCC_URL,
        withCredentials: true,
        headers: {
            Authorization: "Bearer " + queryData.queryKey[2]
        }
    }).then((data) => {
        return data.data
    });
};

export const useCustomersInfoQuery = (uid, accessToken) => {
    return useQuery({
        retry: 1,
        queryKey: ["getUserInfo", uid, accessToken],
        enabled: false,
        queryFn: fetchCustomersInfo,
        select: (data) => {
            return data;
        },
    });
};