import { combineReducers, configureStore } from "@reduxjs/toolkit";
import confirmReducer from "../reducer/confirmSlice";
import alertReducer from "../reducer/alertSlice";
import customersReducer from "../reducer/customersSlice";
import policyReducer from "../reducer/policySlice";

const reducers = combineReducers({
    confirm: confirmReducer,
    alert: alertReducer,
    customers: customersReducer,
    policy: policyReducer,
})
export const store = configureStore({
    reducer: reducers,
})