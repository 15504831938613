import styled from "styled-components";
import mixins, { media } from "../../styles/mixins";

const Wrap = styled.div`
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    padding: 130px 0 200px;
    min-height: 100vh;

    
    > * {
        width: 1200px;
        margin: 0 auto;
    }

    
    ${media.mobile`
        padding: 80px 0;
        
        > * {
            width: 100%;
        }
    `}
`;

const Line = styled.div`
    width: 100vw;
    max-width: 100%;
    height: 1px;
    background-color: #EFEFEF;
    margin-top: 80px;
    margin-bottom: 80px;

    ${media.mobile`
        margin-top: 25px;
        margin-bottom: 25px;
    `}
`

const Logo = styled.div`
    width: 62px;
    ${media.mobile`
        width: 30px;
    `}

    img {
        display: block;
        width: 100%;
    }
`;

const PageHeader = styled.header`
    > h2 {
        ${mixins.fontStyle("40px", "600", "54.48px")}
        margin-top: 10px;
        min-height: 54.48px;
    }
    
    
    ${media.mobile`
        padding: 0 20px 24px;
        
        > h2 {
            ${mixins.fontStyle("20px", "600", "27.24px")}
            word-break: keep-all;
            margin-top: 5px;
            min-height: 27.24px;
        }
    `}
`;

const PageBody = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    ${media.mobile`
        margin-top: 0;
    `}
`;

const PageTemplate = ({title, children}) => {
    return (
        <Wrap>
            <Page>
                <PageHeader>
                    <Logo>
                        <img src={process.env.PUBLIC_URL + "/image/img_logo_amway.svg"} />
                    </Logo>
                    {title && <h2>{title}</h2>}
                </PageHeader>

                <Line></Line>

                <PageBody>
                    {children}
                </PageBody>
            </Page>
        </Wrap>
    );
}
export default PageTemplate;