import { useQuery } from "@tanstack/react-query";
import api from "../../utils/api"

const fetchActivityForm = (reqData) => {
    const eventIdx = reqData.queryKey[1];
    return api({
        url: "/schedule/" +  eventIdx + "/activity",
    }).then((data) => data.data);
}

export const useActivityFormQuery = (eventIdx) => {
    return useQuery({
        retry: 1,
        refetchInterval: 60000, // 1분마다 refetch
        queryKey: ["activityForm", eventIdx],
        queryFn: fetchActivityForm,
        select: (data) => {
            return data.data;
        },
    });
};