import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchHybrisUserInfo = (queryData) => {
    return axios("/api/v2/amwaykorea/sso/authorizeEvent", {
        baseURL: process.env.REACT_APP_VITE_HYBRIS_OCC_URL,
        withCredentials: true
    }).then((data) => {
        return ({
            ...data,
            customerUid: data.customerUid ? decodeURIComponent(window.atob(data.customerUid)) : undefined,
        })
    });
};

export const useHybrisUserInfoQuery = () => {
    return useQuery({
        retry: 1,
        queryKey: ["getHybrisUserInfo"],
        queryFn: fetchHybrisUserInfo,
        select: (data) => {
            return data.data;
        },
    });
};