import { useQuery } from "@tanstack/react-query";
import api from "../../utils/api";

const fetchRelationship = () => {
    return api("/application/relationship").then((data) => data.data);
}

export const useRelationshipQuery = () => {
    return useQuery({
        retry: 1,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryKey: ["relationship"],
        queryFn: fetchRelationship,
        select: (data) => {
            return data.data;
        },
    });
};