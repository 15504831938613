import { useQuery } from "@tanstack/react-query";
import api from "../../utils/api"

const fetchForm = (data) => {
    const eventIdx = data.queryKey[1];
    return api("/schedule/" + eventIdx + "/form").then((data) => data.data);
}

export const useFormQuery = (eventIdx) => {
    return useQuery({
        retry: 1,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryKey: ["form", eventIdx],
        queryFn: fetchForm,
        select: (data) => {
            return data.data;
        },
    });
};