import styled from "styled-components"
import mixins from "../../styles/mixins"

export const Wrap = styled.p`
    ${mixins.caption}
    color: ${(props) => props.color}
`
export default function Caption({children, color}) {
    return (
        <Wrap color={color ? color : "#9B9B9B"}>
            {
                children
            }
        </Wrap>
    )
}