import styled from "styled-components";
import PageTemplate from "../../templates/PageTemplate";
import mixins, { media } from "../../../styles/mixins";
import FormTable, {Wrap as FormTableWrap} from "../../organisms/FormTable";
import Button from "../../atoms/Button";
import FormTitle from "../../organisms/FormTitle";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { useApplicationQuery } from "../../../hooks/api/useApplication";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Error from "../../organisms/Error";
import useApplicationFormUitils from "../../../hooks/useApplicationFormUitils";
import FixedFormTable from "../../organisms/FixedFormTable";
import { maskingUserInfo } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { useScheduleQuery } from "../../../hooks/api/useSchedule";

const TextCont = styled.div`
    ${mixins.fontStyle("40px", "400", "54.48px")}
    color: #000;

    .bold {
        font-weight: 600;
    }

    ${media.mobile`
        padding: 40px 20px;
        ${mixins.fontStyle("20px", "400", "27.24px")}
    `}
`;

const Wrap = styled.div`
    margin-top: 68px;
    
    .btn-modify {
        margin-top: 120px;
    }

    // mobile
    ${media.mobile`
        margin-top: 0;
        .btn-modify {
            margin-top: 60px;
        }
    `}
    // /mobile
`;

const Section = styled.section`
    & ~ & {
        margin-top: 200px;
    }

    &.abo-information {
        table {
            th {
                text-align: left;
            }
            tbody {
                td:not(:first-of-type:last-of-type) {
                    width: ${(420/1200)*100}%;
                }
            }
        }
    }

    // 액티비티 예약
    &.activity-reservation {
        // table
        table {
            tbody {
                th, td {
                    border-color: #ADADAD !important;
                }
                tr {
                    td {
                        padding: 27px 44px 27px 0;

                        .activity-name {
                            display: inline-block;
                            width: 272px;
                            ${mixins.fontStyle("18px", "600", "24.52px")}
                            color: #000;
                        }
                        
                        .activity-category {
                            display: inline-block;
                            ${mixins.fontStyle("18px", "400", "24.52px")}
                            color: rgba(0, 0, 0, 0.4);
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        // /table
    }
    // 액티비티 예약

    ${FormTableWrap} {
        padding-top: 80px;

        ${media.desktop`
            &.pay {
                table {
                    border-top: 4px solid #000;
                    border-bottom: 4px solid #000;
                }
            }
        `}

        // table
        table {
            th, td {
                &:not(.empty) {
                    border-color: #EFEFEF;
                }
            }
            tbody {
                td {
                    &:not(.empty) {
                        color: #000;
                    }

                    &.empty {
                        text-align: center;
                        height: 360px;
                        div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
        // table 
    }

    /* mobile */
    ${media.mobile`
        & ~ & {
            margin-top: 0;
            
            &::before {
                content: "";
                display: block;
                height: 10px;
                background-color: #E8E8E8;
            }    
        }

        .pay {
            &::before {
                content: "";
                display: block;
                height: 10px;
                background-color: #E8E8E8;
            }  
        }

        // activity-reservation
        &.activity-reservation {
            // FormTableWrap
            ${FormTableWrap} {
                background-color: #F6F6F6;

                & ~ ${FormTableWrap} {
                    padding-top: 10px;

                }

                &:last-child {
                    border-bottom: 1px solid #ADADAD;
                }

                // table
                table {
                    & ~ table {
                        margin-top: 5px;
                    }
                    th, td {
                        border-bottom: 0 !important;
                        padding-left: 20px !important;
                        padding-right: 20px !important;
                    }

                    th {
                        &::after {
                            background-color: #000;
                        }
                    }

                    thead {
                        th {
                            &::after {
                                width: 100%;
                                left: 0;
                                height: 2px;
                            }
                        }
                    }
                    
                    tbody {
                        tr {
                            position: relative;

                            & ~ .day {
                                border-top: 5px solid #F6F6F6;
                            }

                            &.day {
                                & + tr {
                                    th {
                                        &::before {
                                            background-color: #000;
                                            content: "";
                                            display: block;
                                            position: absolute;
                                            width: calc(100% - 36px);
                                            height: 1px;
                                            top: 0;
                                            left: 18px;
                                        }
                                    }
                                }
                            }
                                
                            th {
                                padding: 18px 0;
                                
                            }

                            td {
                                .activity-category {
                                    ${mixins.fontStyle("14px", "400", "19.07px")}
                                }

                                &:not(.empty) {
                                    &::before {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        width: calc(100% - 36px);
                                        height: 1px;
                                        top: 0;
                                        left: 18px;
                                        background-color: #ADADAD;
                                    }
                                }
                            }
                        }
                    }
                }    
                // /table
            }
            // /FormTableWrap
        }
        // /activity-reservation

        ${FormTableWrap} {
            padding-top: 0;
            table {
                thead {
                    th {
                        padding: 30px 14px 20px;
                    }
                }
                th {
                    word-break: keep-all;
                }
            }
        }
    `}
    /* mobile */
`;

const BtnCont = styled.div`
    display: flex;
    gap: 0 40px;
    ${media.mobile`
        padding: 0 20px;
        margin-top: 60px;
        gap: 0 20px;
    `}
`

const ApplicationComplete = () => {
    const { isMobile } = useDeviceSize();
    const { applicationIdx, scheduleIdx } = useParams();

    const applicationQuery = useApplicationQuery({applicationIdx, scheduleIdx});
    
    const [entrepreneurItems, setEntrepreneurItems] = useState();

    const [items3, setItems3] = useState([]);

    const navigate = useNavigate();
    const customersInfo = useSelector(state => state.customers).value;

	const scheduleQuery = useScheduleQuery(scheduleIdx, customersInfo[0]?customersInfo[0].uid:"");

    
    /**
     * 참가자 정보 데이터 가공
     *
     * @param {*} users
     * @return {*} 
     */
    const getInfoTableStructure = (users) => {
        const max = isMobile ? 1 : 2;
        const tableCnt = Math.ceil(users.length/max);

        let dataArray = [];
        let userCnt = 0;


        // table 개수 만큼 반복
        for (let i = 0; i < tableCnt; i++) {
            const userInfo = {
                is_required: "false",
                user: [],
                items: []
            };

            
            const category = [];
            for (let j = 0; j < max; j++) { // 한 테이블 당 들어가는 유저
                const currentUser = users[userCnt]; // user 신청 정보
                if (currentUser) {
                    userInfo['user'].push({
                        type_text: currentUser?.type_text,
                        is_join: currentUser.fixed_form_value.filter(v => v.name == "is_join")[0]?.value
                    });
    
                    
                    currentUser?.fixed_form_value.forEach((fixedForm, fixedFormIdx) => {
                        const findIdx = category.findIndex(v => v.name == fixedForm.name);
                        // console.log(fixedForm);
                        if (fixedForm.is_show == "true") {
                            const valueObj = {
                                type_text: currentUser.type_text,
                                value: fixedForm.value ? maskingUserInfo(fixedForm.name, fixedForm.value) : "-"
                            };
                            if (findIdx == -1) {
                                category.push({
                                    is_required: fixedForm.is_required,
                                    title: fixedForm.title,
                                    name: fixedForm.name,
                                    items: [
                                        valueObj
                                    ]
                                });
                            } else {
                                category[findIdx]['items'].push(valueObj);
                            }
                        }
                    });
                    userCnt++;
                }
            }
            userInfo.items = category;
            dataArray.push(userInfo); // table
        }

        return dataArray;
    };

    /**
     * 액티비티 예약 테이블 데이터 가공
     *
     * @param {*} users
     * @param {*} userCountPerTable
     * @return {*} 
     */
    const getActivityTableStructure = (users) => {
        const max = isMobile ? 1 : 2;
        const tableCnt = Math.ceil(users.length/max);

        let dataArray = [];
        let userCnt = 0;
        // table 개수 만큼 반복
        for (let i = 0; i < tableCnt; i++) {
            const userInfo = {
                user: [],
                items: {}
            };

            for (let j = 0; j < max; j++) { // 한 테이블 당 들어가는 유저
                const currentUser = users[userCnt]; // user 신청 정보
                if (currentUser) {
                    userInfo['user'].push({
                        idx: currentUser.idx,
                        type_text: currentUser?.type_text,
                        is_join: currentUser.fixed_form_value.filter(v => v.name == "is_join")[0].value
                    });

                    currentUser?.activity.forEach((activityInfo) => { // 신청 가능한 액티비티 
                        const dayStr = "day" + activityInfo.day
                        let selectedActivity = null;

                        activityInfo.lists.forEach(myActivity => {
                            if (myActivity.is_select) {
                                const findActivityIdx = currentUser.my_activity.findIndex(v => v.activity_schedule_idx === myActivity.activity_schedule_idx);
                                selectedActivity = {
                                    type_text: currentUser.type_text,
                                    title: myActivity.title,
                                    start_time: myActivity.start_time,
                                    activity_form_value: currentUser.my_activity[findActivityIdx].activity_form_value
                                };
                            }
                        });
                        // console.log(currentUser.type_text + " " +(day+1) + "일차", selectedActivity);
                        
                        if (selectedActivity) {
                            if (!userInfo.items[dayStr]) {
                                // userInfo.items[dayStr] = [];
                                userInfo.items[dayStr] = {};
                            }
                            // userInfo.items[dayStr].push(selectedActivity);
                            userInfo.items[dayStr][currentUser.idx] = selectedActivity;
                        }
                        
                    });

                    userCnt++;
                }
            }

            dataArray.push(userInfo); // table
        }
        console.log(dataArray);
        return dataArray;
    };

    const isMount = useRef(false);
    useEffect(() => {
        if (!isMount.current) {
            applicationQuery.refetch();
            isMount.current = true;
        }
    }, []);


    useEffect(() => {
        if (applicationQuery.data) {
            const tempItems3 = [];
            const tempEntrepreneurItems = {};
            const tempEntrepreneurCategory = [];
            
            for (const key in applicationQuery.data.application) {
                const value = applicationQuery.data.application[key];
                if (value.type === 3) {
                    tempItems3.push(value);
                } else {
                    if (value.type === 1) {
                        tempEntrepreneurItems['items1'] = value;
                    } else if (value.type === 2) {
                        tempEntrepreneurItems['items2'] = value;
                    }
                    value.fixed_form_value.forEach(el => {
                        if (tempEntrepreneurCategory.findIndex(v => v.name == el.name) < 0) {
                            tempEntrepreneurCategory.push({
                                name: el.name,
                                title: el.title
                            })
                        }
                    });
                }

                setEntrepreneurItems(tempEntrepreneurItems);
                setItems3(tempItems3);
            }
        }
    }, [applicationQuery.data]);

    if (applicationQuery.data) {
        return (
            <PageTemplate title={`${applicationQuery.data.application[0].title} - ${applicationQuery.data.application[0].order}차수`}>
                <Wrap>
                    <TextCont>
                        <p className="bold">
                            신청이 완료되었습니다.
                        </p>
                        <p>
                            아래 신청 내용을 확인해주세요.
                        </p>
                    </TextCont>

                    {
                    <Section className="abo-information">
                        {   
                            <>
                                {
                                    entrepreneurItems && 
                                    <RenderInfoTable data={getInfoTableStructure([entrepreneurItems['items1'], entrepreneurItems['items2']])} />
                                }

                                {
                                    items3.length > 0 && 
                                    <RenderInfoTable data={getInfoTableStructure(items3)} />
                                }
                            </>
                        }

                        {/* 공통항목 */}
                        {
                            Object.keys(applicationQuery.data.payment).length > 0 &&
                            <FixedFormTable className="pay">
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <th className="required">
                                            <span>
                                                결제방식 선택
                                            </span>
                                        </th>
                                        <td>
                                            {
                                                applicationQuery.data.payment.payment_type ? applicationQuery.data.payment.payment_type : "-"
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            applicationQuery.data.payment.payment_type === "카드" &&
                                            <>
                                                <th className="required">
                                                    <span>
                                                        결제 수신자 핸드폰번호
                                                    </span>
                                                </th>
                                                <td>
                                                    {
                                                        applicationQuery.data.payment.payment_phone
                                                    }
                                                </td>
                                            </>
                                        }
                                        {
                                            applicationQuery.data.payment.payment_type === "계좌이체" &&
                                            <>
                                                <th className="required">
                                                    <span>
                                                        입금자명
                                                    </span>
                                                </th>
                                                <td>
                                                    {
                                                        applicationQuery.data.payment.payment_name
                                                    }
                                                </td>
                                            </>
                                        }
                                    </tr>
                                </tbody>
                            </FixedFormTable>
                        }
                    </Section>
                    }

                    {/* 액티비티 예약 */}
                    {
                    <Section className="activity-reservation">
                        <FormTitle>액티비티 예약</FormTitle>

                        {
                            <>
                                {/* 주사업자/부사업자 액티비티 예약 */}
                                {
                                    entrepreneurItems &&
                                    <RenderActivityTable data={getActivityTableStructure([entrepreneurItems['items1'], entrepreneurItems['items2']])} />
                                }

                                {/* 동반가족 액티비티 예약 */}
                                {
                                    items3.length > 0 &&
                                    <RenderActivityTable data={getActivityTableStructure(items3)} />
                                }
                            </>
                        }
                    </Section>
                    }

                    <BtnCont>
                        <Button type="button" className="btn-modify" onClick={() => {
                            navigate("/application/"+applicationQuery.data.information.url_path+"/modify")
                        }}>
                            신청 정보 수정하기
                        </Button>
                        {
                            scheduleQuery.data?.target == "activity" &&
                            <Button type="button" className="btn-modify" onClick={() => {
                                navigate("/application/"+applicationQuery.data.information.url_path+"/activity")
                            }}>
                                액티비티 선택(수정)하기
                            </Button>
                        }
                    </BtnCont>
                </Wrap>
            </PageTemplate>
        ) 
    }
    
    if (applicationQuery.isLoading || applicationQuery.isFetching) {
        return (
            <PageTemplate>
                <Error error={false} msg="잠시만 기다려 주세요..."/>
            </PageTemplate>

        )
    }

    if (applicationQuery.isError) {
        return (
            <PageTemplate>
                <Error msg={applicationQuery.error?.response?.data?.msg?.ko}/>
            </PageTemplate>
        )
    }
}



/**
 * 액티비티 예약 테이블 그리기
 *
 * @param {*} data
 * @return {*} 
 */
const RenderActivityTable = ({data}) => {
    const { isMobile } = useDeviceSize();

    const getRowspan = (type) => {
        let result = 0;
        let i = 0;
        for (const key in type) {
            const userList = type[key];
            let criteria = userList[Object.keys(userList)[0]];
            if (userList.length == 2 && userList[Object.keys(userList)[0]] < userList[Object.keys(userList)[1]]) {
                criteria = userList[Object.keys(userList)[1]];
            }
            result += criteria.activity_form_value.length + 1;
            i++;
        }

        return result ? result : 1;
    }

    return (
        data?.map((type, tableIdx) => {
            const rowspan = isMobile ? 1 : getRowspan(type.items);
            return (
                <FormTable key={`activity_table_${tableIdx}`}>
                    <colgroup>
                        {
                            !isMobile &&
                            <>
                                <col style={{width: "120px"}}/>
                                <col />
                                <col style={{width: "45%"}}/>
                            </>
                        }
                    </colgroup>
                    <thead>
                        <tr>
                            {
                                !isMobile && <td></td>
                            }
                            {
                                type.user.map((user) => {
                                    return (
                                        <th key={`activity_${user.type_text}`} colSpan={isMobile ? 2 : 1}>
                                            {user.type_text}
                                        </th>
                                        
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            // 해당 테이블의 신청 정보 없는 경우
                            Object.keys(type.items).length == 0 ?
                            <tr>   
                                {
                                    !isMobile && 
                                    <td></td>
                                }
                                {
                                    type.user.map(user => {
                                        return (
                                            <td className="empty" key={`activity_empty_${user.idx}`}>정보 없음</td>
                                        )
                                    })
                                }
                            </tr>
                            : 
                            Object.keys(type.items).map((day, dayIdx) => {
                                const userList = type.items[day];

                                const firstUserKey = Object.keys(userList)[0];
                                const secondUserKey = Object.keys(userList)[1];
                                let criteria = userList[firstUserKey];
                                if (userList.length == 2 && userList[firstUserKey].activity_form_value.length < userList[secondUserKey].activity_form_value.length) {
                                    criteria = userList[secondUserKey];
                                }
                                
                                if (Object.keys(userList).length > 0) { // 액티비티 정보 있는 날만
                                    let isJoin;
                                    if (isMobile) {
                                        isJoin = type['user'].filter(v => v.type_text == userList[firstUserKey].type_text)[0].is_join;
                                    }


                                    return (
                                        <React.Fragment key={`activity_${day}`}>
                                            {
                                                (isMobile && isJoin == "참여") &&
                                                <tr className="day">
                                                    <th colSpan={2}>{day.toUpperCase()}</th>
                                                </tr>
                                            }

                                            
                                            {
                                            <tr>
                                                {
                                                    /* day */
                                                    !isMobile &&
                                                    <th rowSpan={criteria?.activity_form_value?.length ? criteria.activity_form_value.length + 1 : 1}>
                                                        {day.toUpperCase()}
                                                    </th>
                                                }
                                                
                                                {
                                                /* 선택 액티비티 타이틀 (시작 시간) */
                                                type.user.map((userItem, userCnt) => {
                                                    const myActivity = userList[userItem.idx];
                                                    const isJoin = userItem.is_join;
                                                    if (isJoin == "참여") {
                                                        return (
                                                            <th key={`activity_selected_${type.user[userCnt].type_text}_${myActivity?.title}`}>
                                                                {myActivity?.title} {myActivity?.start_time && `(${myActivity?.start_time})`}
                                                            </th>
                                                        )
                                                    } else {
                                                        return (
                                                            dayIdx === 0 &&
                                                            <td key={`activity_empty_${day}_${userCnt}`} className="empty" rowSpan={rowspan}>
                                                                <div>
                                                                    정보 없음
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                })
                                                }
                                            </tr>
                                            }
                                            {
                                                // 선택 액티비티 상세 항목
                                                criteria?.activity_form_value?.map((criteriaForm, formIdx) => {
                                                    return (
                                                        <tr key={`activity_form_${formIdx}`}>
                                                            {
                                                                Object.keys(userList).map((userIdx, myActivityIdx) => {
                                                                    const myActivity = userList[userIdx];
                                                                    const isJoin = type['user'].filter(v => v.type_text == myActivity.type_text)[0].is_join;
                                                                    const formInfo = myActivity.activity_form_value[formIdx];
                                                                    if (isJoin === "참여") {
                                                                        return (
                                                                            formInfo 
                                                                            ? 
                                                                            <td key={`my_activity_${myActivityIdx}`}>
                                                                                <div className="activity-category">{formInfo.title?.split("\n").map(el => <>{el}<br/></>)}</div>
                                                                                <div>
                                                                                {
                                                                                    ((!formInfo || !formInfo?.value || formInfo?.value == "false") && formInfo?.value !== 0) 
                                                                                    ?
                                                                                    "-"
                                                                                    :
                                                                                    formInfo?.value?.toString()
                                                                                    
                                                                                }
                                                                                </div>
                                                                            </td>
                                                                            : <td className="empty"><div>-</div></td>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                }
                            })
                        }
                    </tbody>
                </FormTable>
            )
        })
    )
}

/**
 * 참가자 정보 테이블 그리기
 *
 * @param {*} data
 * @return {*} 
 */
const RenderInfoTable = ({data}) => {
    const { isMobile } = useDeviceSize();
    const { getCategoryDescription } = useApplicationFormUitils();
    const customersInfo = useSelector(state => state.customers).value;

    return (
        data?.map(table => {
            return (
                <FixedFormTable>
                    <thead>
                        <tr>
                            {
                                !isMobile &&
                                <td></td>
                            }
                            {table.user.map(user => {
                                return (
                                    <th key={`info_type_text_${user.type_text}`} colSpan={isMobile ? 2 : 1}>
                                        {user.type_text}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {table.items.map((category, categoryIdx) => {
                            const description = getCategoryDescription(category.name);
                            let isColumn = false;
                            if (category.name === "allergy") {
                                isColumn = true;
                            }

				            // 안내 이미지 항목 제외
				            // 공동 사업자일 때 비사업배우자 참가신청서 제외
                            let isDrawForMo = false;
                            if (category.name !== "family_guide_attachment" && (!(category.name === "join_attachment" && customersInfo.length === 2))) {
                                if (isMobile) {
                                    return (
                                        <>
                                            <tr className={description ? "border-none" : ""}>
                                                {
                                                    table.user[0].is_join === "불참"
                                                    ? 
                                                    null
                                                    :
                                                    <th colSpan={isColumn ? 2 : 1} className={category.is_required == "true" ? "required" : ""}>
                                                        <span>
                                                            {
                                                                category.title
                                                            }
                                                        </span>
                                                    </th>
                                                }


                                                {
                                                    !isColumn && 
                                                    table.user.map(user => {
                                                        console.log(user, categoryIdx);
                                                        if (category.name === "is_join") {
                                                            if (user.is_join === "불참" || !user.is_join) {
                                                                if (!isDrawForMo) {
                                                                    isDrawForMo = true;
                                                                    return <td className="empty" rowSpan={table.items.length}>정보 없음</td>
                                                                }
                                                            }
                                                        }
                                                        if (user.is_join == "참여") {
                                                            return (
                                                                <td>
                                                                    {
                                                                    category.items?.map(value => {
                                                                        if (user.type_text == value.type_text) {
                                                                            return value.value
                                                                        }
                                                                    })
                                                                    }
                                                                </td>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tr>
                                            {
                                                table.user[0].is_join !== "불참" &&
                                                <>
                                                {
                                                    isColumn &&
                                                    <tr className="border-none">
                                                        <td colSpan={2}>
                                                            {category.items[0].value}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    (description && table.user[0].is_join === "참여") &&
                                                    <tr className="desc">
                                                        <td colSpan={2}>
                                                            {description}
                                                        </td>
                                                    </tr>
                                                }
                                                </>
                                            }
                                        </>
                                    )
                                } else {
                                    return (
                                        <tr>
                                            {
                                                <th className={category.is_required == "true" ? "required" : ""}>
                                                    <span>
                                                        {
                                                            category.title
                                                        }
                                                    </span>

                                                    {description}
                                                </th>
                                            }

                                            {
                                                table.user.map(user => {
                                                    if (category.name === "is_join") {
                                                        if (user.is_join === "불참" || !user.is_join) {
                                                            return <td className="empty" rowSpan={table.items.length}>정보 없음</td>
                                                        }
                                                    }

                                                    if (user.is_join == "참여") {
                                                        return (
                                                            <td>
                                                                {
                                                                category.items?.map(value => {
                                                                    if (user.type_text == value.type_text) {
                                                                        if (typeof value.value == "string") {
                                                                            return value?.value
                                                                        } else {
                                                                            return value?.value?.value
                                                                        }
                                                                    }
                                                                })
                                                                }
                                                            </td>
                                                        )
                                                    }
                                                })
                                            }
                                        </tr>
                                    )
                                }
                            }
                        })}
                    </tbody>
                </FixedFormTable>
            )
        })
    )
}
export default ApplicationComplete;